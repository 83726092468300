<template>
  <v-app>
    <!-- <div class="field-form">
      <pre>
        {{ form.body_problem_concern }}
        {{ form.body_problem_concern_position }}
        {{ form.body_problem_concern_questionnaire }}
      </pre>
    </div> -->

    <div class="custom-layout">
      <v-main class="main">
        <div class="custom-container">
          <router-view />
        </div>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState({
      form: (state) => state.questionnaire.form,
    }),
  },
  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
.custom-layout {
  background-color: #000000;

  .custom-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    background-color: #fff;
  }
}

.field-form {
  position: absolute;
  top: 80px;
  left: 0;
  color: #00f60c;
  background-color: #000000;
  font-size: 8px;
}
</style>
