<template>
  <div class="step-9">
    <v-layout justify-center>
      <v-flex shrink>
        <v-img
          src="../../assets/images/step3-gender.png"
          height="120"
          contain
        ></v-img>
      </v-flex>
    </v-layout>

    <div class="step-title text-center my-6">
      คุณเริ่มรู้สึกถึงอาการ <br />
      /ปัญหา ตั้งแต่เมื่อใด?
    </div>

    <div style="padding-bottom: 8rem">
      <box-card class="py-3 custom_checkbox_radio">
        <v-radio-group
          v-model="
            form.body_problem_concern_questionnaire[indexAt]
              .question_9_select_name
          "
          :rules="[(v) => !!v || 'กรุณาเลือกอาการ/ปัญหา']"
          @change="onChangeRadio"
        >
          <v-radio color="#C71010" class="mb-6 px-3 mt-0" value="ไม่รู้">
            <template v-slot:label>
              <span class="font-24" style="color: #000000"> ไม่รู้ </span>
            </template>
          </v-radio>

          <v-divider />

          <v-radio
            color="#C71010"
            class="mt-6 mb-6 px-3"
            value="มากกว่าหนึ่งปีที่ผ่านมา"
          >
            <template v-slot:label>
              <span class="font-24" style="color: #000000">
                มากกว่าหนึ่งปีที่ผ่านมา
              </span>
            </template>
          </v-radio>

          <v-divider />

          <v-radio color="#C71010" class="mt-3 mb-3 px-3" value="เดือนที่แล้ว">
            <template v-slot:label>
              <v-layout align-center>
                <v-flex xs4>
                  <v-autocomplete
                    v-model="
                      form.body_problem_concern_questionnaire[indexAt]
                        .question_9_last_month
                    "
                    :items="months"
                    :disabled="
                      form.body_problem_concern_questionnaire[indexAt]
                        .question_9_select_name != 'เดือนที่แล้ว'
                    "
                    hide-details
                    menu-props="offsetY"
                    color="#000"
                    outlined
                    :rules="
                      form.body_problem_concern_questionnaire[indexAt]
                        .question_9_select_name == 'เดือนที่แล้ว'
                        ? [(v) => !!v || 'กรุณาเลือกจำนวนเดือน']
                        : []
                    "
                    required
                  ></v-autocomplete>
                </v-flex>
                <v-flex
                  xs6
                  class="pl-2 font-24"
                  :style="
                    form.body_problem_concern_questionnaire[indexAt]
                      .question_9_select_name != 'เดือนที่แล้ว'
                      ? { color: '#A3A3A3' }
                      : { color: '#000' }
                  "
                >
                  เดือนที่แล้ว
                </v-flex>
              </v-layout>
            </template>
          </v-radio>

          <v-divider />

          <v-radio
            color="#C71010"
            class="mt-3 mb-3 px-3"
            value="สัปดาห์ที่ผ่านมา"
          >
            <template v-slot:label>
              <v-layout align-center>
                <v-flex xs4>
                  <v-autocomplete
                    v-model="
                      form.body_problem_concern_questionnaire[indexAt]
                        .question_9_last_week
                    "
                    :items="weeks"
                    :disabled="
                      form.body_problem_concern_questionnaire[indexAt]
                        .question_9_select_name != 'สัปดาห์ที่ผ่านมา'
                    "
                    hide-details
                    menu-props="offsetY"
                    color="#000"
                    outlined
                    :rules="
                      form.body_problem_concern_questionnaire[indexAt]
                        .question_9_select_name == 'สัปดาห์ที่ผ่านมา'
                        ? [(v) => !!v || 'กรุณาเลือกจำนวนเดือน']
                        : []
                    "
                    required
                  ></v-autocomplete>
                </v-flex>
                <v-flex
                  xs6
                  class="pl-2 font-24"
                  :style="
                    form.body_problem_concern_questionnaire[indexAt]
                      .question_9_select_name != 'สัปดาห์ที่ผ่านมา'
                      ? { color: '#A3A3A3' }
                      : { color: '#000' }
                  "
                >
                  สัปดาห์ที่ผ่านมา
                </v-flex>
              </v-layout>
            </template>
          </v-radio>
        </v-radio-group>
      </box-card>
    </div>
  </div>
</template>

<script>
// import { Icon } from "@iconify/vue2";
import { mapState } from "vuex";
import "vue-scroll-picker/dist/style.css";
import BoxCard from "../BoxCard.vue";

export default {
  components: { BoxCard },
  // components: { Icon },
  name: "QuestionnaireForm",

  props: {
    indexAt: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      weeks: [1, 2, 3, 4],
      validStep8: true,
    };
  },

  computed: {
    ...mapState({
      step: (state) => state.questionnaire.step,
      form: (state) => state.questionnaire.form,
    }),
  },

  methods: {
    onChangeRadio() {
      this.form.body_problem_concern_questionnaire[
        this.indexAt
      ].question_9_last_week = null;
      this.form.body_problem_concern_questionnaire[
        this.indexAt
      ].question_9_last_month = null;
    },
  },
};
</script>

<style lang="scss">
.step-9 {
  .v-select.v-text-field input {
    // font-size: 18px;
  }
}
</style>
