<template>
  <div class="step-7">
    <div v-for="(item, i) in form.body_problem_concern_position" :key="i">
      <div v-if="step_no === i + 1" style="padding-bottom: 6rem">
        <v-layout justify-center align-center pt-2>
          <v-flex shrink>
            <v-btn
              height="39px"
              depressed
              dark
              style="border-radius: 35px"
              color="#C71010"
            >
              <span class="font-24 font-w-600">
                {{ item.problem_name }} ( {{ i + 1 }} /
                {{ form.body_problem_concern_position.length }})
              </span>
            </v-btn>
          </v-flex>
        </v-layout>

        <div class="step-title text-center mt-5">
          ตำแหน่งที่มีอาการ/ <br />
          ปัญหาคือบริเวณใด?
        </div>

        <v-layout justify-center my-6>
          <v-flex xs12 style="position: relative" class="d-flex justify-center">
            <div class="body_model">
              <img
                src="../../assets/images/body-position/body.png"
                width="332"
                height="309"
                contain
              />

              <div
                class="part_position"
                v-for="(body_position, i) in body_positions"
                :key="i"
              >
                <img
                  v-if="checkShowImage(item.positions, body_position.name)"
                  :src="
                    require(`../../assets/images/body-position/${body_position.image}.png`)
                  "
                  width="332"
                  height="309"
                  alt=""
                />
              </div>
            </div>
          </v-flex>
        </v-layout>

        <div class="mt-4">
          <div class="d-flex justify-end">
            <v-btn
              fab
              depressed
              dark
              width="40"
              height="40"
              color="var(--main-color)"
              @click="addPosition(i)"
            >
              <Icon style="font-size: 24px" icon="material-symbols:add" />
            </v-btn>
          </div>

          <div v-for="(position, a) in item.positions" :key="a">
            <label class="font-24 font-w-600">ตำแหน่ง</label>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="position.question_7_position"
                  :items="body_positions"
                  item-text="name"
                  item-value="name"
                  menu-props="offsetY"
                  color="#000"
                  outlined
                  :rules="[(v) => !!v || 'กรุณาเลือกตำแหน่ง']"
                  required
                  @change="onPositionChange(i, a, $event)"
                >
                  <template v-slot:selection="data">
                    <div>{{ data.item.no }}. {{ data.item.name }}</div>
                  </template>
                  <template v-slot:item="data">
                    <div>{{ data.item.no }}. {{ data.item.name }}</div>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="auto" class="pt-5 pl-0">
                <v-btn
                  v-if="item.positions.length > 1"
                  fab
                  depressed
                  dark
                  width="40"
                  height="40"
                  color="red"
                  @click="removePosition(i, a)"
                >
                  <Icon
                    style="font-size: 24px"
                    icon="material-symbols:remove"
                  />
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <div class="px-3 pb-3 text-center mb-6" v-if="!isValid">
            <span style="color: red" class="font-12">
              กรุณาเลือกตำแหน่งที่มีอาการ/ปัญหาคือบริเวณใด
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- <pre>
      {{ form.body_problem_concern_position }}
    </pre> -->

    <!-- Button  step -->
    <v-layout class="btn-step7">
      <v-flex shrink>
        <v-btn
          v-if="step > 1"
          style="border-radius: 30px"
          height="70"
          width="125"
          color="#F3F3F3"
          dark
          depressed
          @click="backStep(step_no - 1)"
        >
          <span class="font-26 font-w-600" style="color: #a3a3a3">
            ย้อนกลับ
          </span>
        </v-btn>
      </v-flex>
      <v-spacer />
      <v-flex shrink>
        <v-btn
          style="border-radius: 30px"
          height="70"
          width="172"
          color="#55C5C8"
          :dark="step !== 15"
          depressed
          :disabled="step === 15"
          @click="nextStep(step_no + 1)"
        >
          <span class="font-32 font-w-600">ถัดไป </span>
          <Icon style="font-size: 3rem" icon="material-symbols:navigate-next" />
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Icon } from "@iconify/vue2";
import { body_positions } from "../../Constants";

export default {
  name: "QuestionnaireForm",
  props: {},
  components: {
    Icon,
  },
  computed: {
    ...mapState({
      step: (state) => state.questionnaire.step,
      form: (state) => state.questionnaire.form,
    }),
  },
  data() {
    return {
      positions: ["มือ(ซ้าย)", "มือ(ขวา)", "ต้นขา(ซ้าย)", "เท้า(ซ้าย)"],
      body_problem_concern: [],
      step_no: 1,
      isValid: true,
      body_positions,
    };
  },
  created() {
    // if (this.form.body_problem_concern_position.length > 0) {
    //   return;
    // }
    // let arr = [];
    // // เลือกมาแค่ question_6_problem_name ที่อยู่ใน array body_problem_concern = "ความเจ็บปวด, ตัวเย็นขี้หนาว, ร้อนวูบวาบ, บวม"
    // this.form.body_problem_concern.forEach((e) => {
    //   if (
    //     e.question_6_problem_name === "ความเจ็บปวด" ||
    //     e.question_6_problem_name === "ตัวเย็นขี้หนาว" ||
    //     e.question_6_problem_name === "ร้อนวูบวาบ" ||
    //     e.question_6_problem_name === "บวม"
    //   ) {
    //     arr.push({
    //       problem_name: e.question_6_problem_name,
    //       positions: [
    //         {
    //           question_7_position: null,
    //         },
    //       ],
    //     });
    //   }
    // });
    // this.form.body_problem_concern_position = arr;
  },
  methods: {
    onPositionChange(index, index2, val) {
      if (this.checkValid(this.step_no - 1)) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
      console.log(index, val);
      this.$store.commit("questionnaire/setValueStep7", {
        index,
        index2,
        val,
      });
    },
    addPosition(index) {
      this.$store.commit("questionnaire/addPositionStep7", index);
    },
    removePosition(index, positionIndex) {
      console.log(index, positionIndex);
      this.$store.commit("questionnaire/removePositionStep7", {
        index,
        positionIndex,
      });

      if (this.checkValid(this.step_no - 1)) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    },
    nextStep(step) {
      console.log(step);
      if (this.checkValid(step - 1)) {
        this.isValid = true;
        // ถ้า step = จำนวนของ body_problem_concern_position ให้ไป step 8 (แสดงว่าทำ step 7 ครบแล้ว)
        if (step - 1 == this.form.body_problem_concern_position.length) {
          this.$store.commit("questionnaire/setStep", 8);
          this.$store.commit("questionnaire/setStepBefore", 7);
        } else {
          this.step_no++;
        }
      } else {
        this.isValid = false;
      }
    },
    backStep(step) {
      // ถ้า step = 0 ให้กลับไป step 6
      if (step == 0) {
        this.$store.commit("questionnaire/setStep", 6);
        this.$store.commit("questionnaire/setStepBefore", 5);
      } else {
        if (this.checkValid(step - 1)) {
          this.isValid = true;
          this.step_no--;
        } else {
          this.isValid = false;
        }
      }
    },

    checkValid(step) {
      let index = 0;
      if (step == 1) {
        index = 0;
      } else if (step == 2) {
        index = 1;
      } else if (step == 3) {
        index = 2;
      }
      console.log(index);

      let isValid = true;
      this.form.body_problem_concern_position[index].positions.forEach((e) => {
        console.log(e.question_7_position);
        if (e.question_7_position == null) {
          isValid = false;
        }
      });

      console.log("isValid", isValid);
      return isValid;
    },

    checkShowImage(positions, key) {
      let isShow = false;
      positions.forEach((e) => {
        if (e.question_7_position == key) {
          isShow = true;
        }
      });
      console.log("isShow", key, isShow);
      return isShow;
    },
  },
};
</script>

<style lang="scss">
.step-7 {
  position: relative;
  min-height: calc(100vh - 58px);

  .btn-step7 {
    position: absolute;
    width: 100%;
    bottom: 15px;
    z-index: 1;
  }
}
.problem-image {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 1;
}

.body_model {
  position: relative;
  margin-top: 20px;
  padding-left: 10px;
  .part_position {
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 1;
  }
}
</style>
