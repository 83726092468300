<template>
  <div class="step-12">
    <v-layout justify-center>
      <v-flex shrink>
        <v-img
          src="../../assets/images/step-13.png"
          height="120"
          contain
        ></v-img>
      </v-flex>
    </v-layout>

    <div class="step-title text-center">
      คุณสูบบุหรี่ <br />
      หรือไม่?
    </div>

    <div style="padding-bottom: 8rem">
      <box-card class="py-3 custom_checkbox_radio">
        <v-radio-group
          v-model="form.question_13_smoke"
          :rules="[(v) => !!v || 'กรุณาตอบคำถาม']"
          @change="onChangeRadio"
        >
          <v-radio color="#C71010" class="mb-6 px-3" value="ไม่สูบ">
            <template v-slot:label>
              <span class="font-24" style="color: #000000"> ไม่สูบ </span>
            </template>
          </v-radio>

          <v-divider />

          <v-radio color="#C71010" class="mt-6 mb-3 px-3" value="สูบ ประมาณ">
            <template v-slot:label>
              <span class="font-24" style="color: #000000"> สูบ ประมาณ </span>
            </template>
          </v-radio>
          <v-layout align-center px-5 pb-6>
            <v-flex xs4>
              <v-autocomplete
                v-model="form.question_13_smoke_day"
                :items="pieces"
                :disabled="form.question_13_smoke != 'สูบ ประมาณ'"
                hide-details
                menu-props="offsetY"
                color="#000"
                outlined
                :rules="
                  form.question_13_smoke == 'สูบ ประมาณ'
                    ? [(v) => !!v || 'กรุณาเลือกจำนวน']
                    : []
                "
                required
              ></v-autocomplete>
            </v-flex>
            <v-flex
              xs6
              class="pl-2 font-24"
              :style="
                form.question_13_smoke != 'สูบ ประมาณ'
                  ? { color: '#A3A3A3' }
                  : { color: '#000' }
              "
            >
              มวน/วัน
            </v-flex>
          </v-layout>

          <v-divider />

          <v-radio
            color="#C71010"
            class="mt-6 mb-3 px-3"
            value="เคยสูบ แต่ตอนนี้เลิกแล้ว เมื่อ"
          >
            <template v-slot:label>
              <span class="font-24" style="color: #000000">
                เคยสูบ แต่ตอนนี้เลิกแล้ว เมื่อ
              </span>
            </template>
          </v-radio>

          <v-layout align-center px-5>
            <v-flex xs4>
              <v-autocomplete
                v-model="form.question_13_smoke_year"
                :items="years"
                :disabled="
                  form.question_13_smoke != 'เคยสูบ แต่ตอนนี้เลิกแล้ว เมื่อ'
                "
                hide-details
                menu-props="offsetY"
                color="#000"
                outlined
                :rules="
                  form.question_13_smoke == 'เคยสูบ แต่ตอนนี้เลิกแล้ว เมื่อ'
                    ? [(v) => !!v || 'กรุณาเลือกจำนวน']
                    : []
                "
                required
              ></v-autocomplete>
            </v-flex>
            <v-flex
              xs6
              class="pl-2 font-24"
              :style="
                form.question_13_smoke != 'เคยสูบ แต่ตอนนี้เลิกแล้ว เมื่อ'
                  ? { color: '#A3A3A3' }
                  : { color: '#000' }
              "
            >
              ปีที่แล้ว
            </v-flex>
          </v-layout>
        </v-radio-group>
      </box-card>
    </div>
  </div>
</template>

<script>
// import { Icon } from "@iconify/vue2";
import { mapState } from "vuex";
import "vue-scroll-picker/dist/style.css";
import BoxCard from "../BoxCard.vue";

export default {
  components: { BoxCard },
  // components: { Icon },
  name: "QuestionnaireForm",

  data() {
    return {
      pieces: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      years: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    };
  },

  computed: {
    ...mapState({
      step: (state) => state.questionnaire.step,
      form: (state) => state.questionnaire.form,
    }),
  },

  created() {
    window.scrollTo(0, 0);
  },

  methods: {
    onChangeRadio() {
      this.form.question_13_smoke_day = null;
      this.form.question_13_smoke_year = null;
    },
  },
};
</script>

<style lang="scss">
.step-12 {
  .v-select.v-text-field input {
    // font-size: 24px;
  }
}
</style>
