var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-10"},[_vm._m(0),_c('div',{staticClass:"font-20 text-center mb-4",staticStyle:{"color":"#a3a3a3"}},[_vm._v(" (*เลือกตอบได้หลายข้อ) ")]),_c('div',{staticStyle:{"padding-bottom":"8rem"}},[_c('box-card',{staticClass:"custom_checkbox_radio"},[_c('div',{staticClass:"px-4 pt-4"},[_c('div',{staticClass:"font-24 font-w-600"},[_vm._v("ตรวจที่โรงพยาบาล/คลินิก")]),_c('v-layout',{attrs:{"align-end":"","justify-space-between":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"rules":[(v) => !!v || 'กรุณาเลือกตรวจที่โรงพยาบาล/คลินิก']},model:{value:(
                _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  .question_11_hospital
              ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  , "question_11_hospital", $$v)},expression:"\n                form.body_problem_concern_questionnaire[indexAt]\n                  .question_11_hospital\n              "}},_vm._l((_vm.radioList),function(item,i){return _c('v-radio',{key:i,staticClass:"pb-4",attrs:{"color":"#C71010","value":item},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-20",staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(item)+" ")])]},proxy:true}],null,true)})}),1)],1),_c('v-flex',{attrs:{"shrink":"","pb-2":""}},[_c('v-img',{attrs:{"width":"80","aspect-ratio":"1","src":require("../../assets/images/step11-1.png")}})],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"px-4 pt-4"},[_c('div',{staticClass:"font-24 font-w-600"},[_vm._v("นวด")]),_c('v-layout',{attrs:{"align-end":"","justify-space-between":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"rules":[(v) => !!v || 'กรุณาเลือกนวด']},model:{value:(
                _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  .question_11_massage
              ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  , "question_11_massage", $$v)},expression:"\n                form.body_problem_concern_questionnaire[indexAt]\n                  .question_11_massage\n              "}},_vm._l((_vm.radioList),function(item,i){return _c('v-radio',{key:i,staticClass:"pb-4",attrs:{"color":"#C71010","value":item},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-20",staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(item)+" ")])]},proxy:true}],null,true)})}),1)],1),_c('v-flex',{attrs:{"shrink":"","pb-2":""}},[_c('v-img',{attrs:{"width":"80","aspect-ratio":"1","src":require("../../assets/images/step11-2.png")}})],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"px-4 pt-4"},[_c('div',{staticClass:"font-24 font-w-600"},[_vm._v("เข้าคลาสโยคะ/พิลาทิส")]),_c('v-layout',{attrs:{"align-end":"","justify-space-between":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"rules":[(v) => !!v || 'กรุณาเลือกเข้าคลาสโยคะ/พิลาทิส'],"required":""},model:{value:(
                _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  .question_11_yoga
              ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  , "question_11_yoga", $$v)},expression:"\n                form.body_problem_concern_questionnaire[indexAt]\n                  .question_11_yoga\n              "}},_vm._l((_vm.radioList),function(item,i){return _c('v-radio',{key:i,staticClass:"pb-4",attrs:{"color":"#C71010","value":item},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-20",staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(item)+" ")])]},proxy:true}],null,true)})}),1)],1),_c('v-flex',{attrs:{"shrink":"","pb-2":""}},[_c('v-img',{attrs:{"width":"80","aspect-ratio":"1","src":require("../../assets/images/step11-3.png")}})],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"px-4 pt-4"},[_c('div',{staticClass:"font-24 font-w-600"},[_vm._v("ไปฟิตเนส")]),_c('v-layout',{attrs:{"align-end":"","justify-space-between":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"rules":[(v) => !!v || 'กรุณาเลือกไปฟิตเนส']},model:{value:(
                _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  .question_11_fitness
              ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  , "question_11_fitness", $$v)},expression:"\n                form.body_problem_concern_questionnaire[indexAt]\n                  .question_11_fitness\n              "}},_vm._l((_vm.radioList),function(item,i){return _c('v-radio',{key:i,staticClass:"pb-4",attrs:{"color":"#C71010","value":item},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-20",staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(item)+" ")])]},proxy:true}],null,true)})}),1)],1),_c('v-flex',{attrs:{"shrink":"","pb-2":""}},[_c('v-img',{attrs:{"width":"80","aspect-ratio":"1","src":require("../../assets/images/step11-4.png")}})],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"px-4 pt-4"},[_c('div',{staticClass:"font-24 font-w-600"},[_vm._v("ออกกำลังกายด้วยตัวเอง")]),_c('v-layout',{attrs:{"align-end":"","justify-space-between":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"rules":[(v) => !!v || 'กรุณาเลือกออกกำลังกายด้วยตัวเอง']},model:{value:(
                _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  .question_11_exercise
              ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  , "question_11_exercise", $$v)},expression:"\n                form.body_problem_concern_questionnaire[indexAt]\n                  .question_11_exercise\n              "}},_vm._l((_vm.radioList),function(item,i){return _c('v-radio',{key:i,staticClass:"pb-4",attrs:{"color":"#C71010","value":item},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-20",staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(item)+" ")])]},proxy:true}],null,true)})}),1)],1),_c('v-flex',{attrs:{"shrink":"","pb-2":""}},[_c('v-img',{attrs:{"width":"80","aspect-ratio":"1","src":require("../../assets/images/step11-5.png")}})],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"px-4 pt-4"},[_c('div',{staticClass:"font-24 font-w-600"},[_vm._v("อื่นๆ")]),_c('v-textarea',{staticClass:"mt-2",attrs:{"rules":_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
              .question_11_exercise_other
              ? [
                  (v) =>
                    (v && v.length <= 150) ||
                    'กรุณากรอกข้อมูลไม่เกิน 150 ตัวอักษร',
                ]
              : [],"rows":"3","color":"#000","background-color":"#fff","placeholder":"โปรดระบุ","outlined":""},model:{value:(
            _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
              .question_11_exercise_other
          ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
              , "question_11_exercise_other", $$v)},expression:"\n            form.body_problem_concern_questionnaire[indexAt]\n              .question_11_exercise_other\n          "}})],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-title text-center mt-6"},[_vm._v(" ที่ผ่านมา "),_c('br'),_vm._v(" คุณทำอะไรไปบ้าง "),_c('br'),_vm._v(" เพื่อแก้ไขอาการ/ปัญหา ")])
}]

export { render, staticRenderFns }