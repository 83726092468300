<template>
  <div class="step-16 px-6">
    <div class="canvas"></div>
    <v-layout justify-start align-center>
      <v-flex shrink>
        <v-btn icon depressed @click="backStep">
          <v-icon large> mdi-chevron-left </v-icon>
        </v-btn>
      </v-flex>
      <v-flex shrink>
        <v-img src="../../assets/logo.png" width="186" contain></v-img>
      </v-flex>
    </v-layout>

    <div style="padding-bottom: 8rem">
      <!-- card 1 -->
      <box-card class="mb-5 pa-4">
        <div class="font-32 font-w-600">ข้อกำหนดและเงื่อนไข</div>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Id voluptas
        fugiat ducimus soluta, officia, placeat autem suscipit eum qui beatae,
        perferendis cupiditate in enim numquam? Doloremque placeat sequi eos
        cupiditate officia expedita quasi distinctio, aperiam qui atque iste
        nemo hic dicta et, nobis libero ut aspernatur adipisci nihil dolorum!
        Facilis consequuntur ad possimus maxime doloremque cupiditate labore
        ullam natus minima. Perspiciatis, consequatur natus reprehenderit fugit
        numquam sint ipsam? Tempora dolores debitis modi temporibus mollitia sed
        dolor eligendi dolore, impedit praesentium! Deleniti est quasi
        voluptatum corporis minus optio, libero in repudiandae? Saepe expedita
        accusantium odit cumque similique dolores sint veritatis modi! Aliquam
        id quae fugiat minus ea. Sequi dignissimos voluptate aperiam sunt
        perferendis laborum, omnis eum facere, repellendus, officiis possimus
        fuga officia facilis nesciunt soluta inventore quibusdam ipsam beatae ab
        incidunt ratione molestiae. Quae, non beatae sequi nostrum omnis dolor,
        sed molestiae eligendi sint dolorem, ipsa aspernatur fugit mollitia
        voluptate eaque.
      </box-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import "vue-scroll-picker/dist/style.css";
import BoxCard from "../BoxCard.vue";

export default {
  components: { BoxCard },
  name: "QuestionnaireForm",

  data() {
    return {};
  },

  computed: {
    ...mapState({
      step: (state) => state.questionnaire.step,
      stepBefore: (state) => state.questionnaire.stepBefore,
      form: (state) => state.questionnaire.form,
    }),
  },

  created() {
    window.scrollTo(0, 0);
  },

  methods: {
    backStep() {
      this.$store.commit("questionnaire/setStep", this.stepBefore);
      this.$store.commit("questionnaire/setStepBefore", this.stepBefore - 1);
    },
  },
};
</script>

<style lang="scss">
.step-16 {
  position: relative;

  .v-select.v-text-field input {
    // font-size: 24px;
  }
}
</style>
