<template>
  <div class="step-5">
    <v-layout justify-center>
      <v-flex shrink>
        <v-img src="../../assets/images/step5.png" height="120" contain></v-img>
      </v-flex>
    </v-layout>

    <div class="step-title text-center my-4">
      สาเหตุที่คิดจะ <br />
      "ตรวจอายุร่างกาย" <br />ในครั้งนี้คืออะไร?
    </div>

    <div class="font-20 mb-6 text-center" style="color: #a3a3a3">
      (*เลือกตอบได้หลายข้อ หากไม่มีปัญหาสุขภาพ จะข้ามไปข้อ 12)
    </div>

    <div style="padding-bottom: 8rem">
      <BoxCard>
        <div class="pa-3" :class="value1 ? 'active-checked' : ''">
          <v-checkbox
            v-model="value1"
            class="mt-0"
            hide-details
            color="#C71010"
            label='เพราะมี "ปัญหาร่างกาย/ความกังวล"'
            value='เพราะมี "ปัญหาร่างกาย/ความกังวล"'
            @change="onSelect('เพราะมี ปัญหาร่างกาย/ความกังวล', $event)"
          ></v-checkbox>
        </div>

        <div class="pa-3" :class="value2 ? 'active-checked' : ''">
          <v-checkbox
            v-model="value2"
            color="#C71010"
            class="mt-0"
            hide-details
            label="เพราะอยากมีอายุยืนยาว"
            value="เพราะอยากมีอายุยืนยาว"
            @change="onSelect('เพราะอยากมีอายุยืนยาว', $event)"
          ></v-checkbox>
        </div>

        <div class="pa-3" :class="value3 ? 'active-checked' : ''">
          <v-checkbox
            v-model="value3"
            color="#C71010"
            class="mt-0"
            hide-details
            label="แทนการตรวจสุขภาพที่โรงพยาบาล"
            value="แทนการตรวจสุขภาพที่โรงพยาบาล"
            @change="onSelect('แทนการตรวจสุขภาพที่โรงพยาบาล', $event)"
          ></v-checkbox>
        </div>

        <div class="pa-3" :class="value4 ? 'active-checked' : ''">
          <v-checkbox
            v-model="value4"
            color="#C71010"
            class="mt-0"
            hide-details
            label="เพราะดูน่าสนใจ"
            value="เพราะดูน่าสนใจ"
            @change="onSelect('เพราะดูน่าสนใจ', $event)"
          ></v-checkbox>
        </div>

        <div class="pa-3" :class="value5 ? 'active-checked' : ''">
          <v-checkbox
            v-model="value5"
            color="#C71010"
            class="mt-0"
            hide-details
            label="เพราะครอบครัว/เพื่อนแนะนำ"
            value="เพราะครอบครัว/เพื่อนแนะนำ"
            @change="onSelect('เพราะครอบครัว/เพื่อนแนะนำ', $event)"
          ></v-checkbox>
        </div>

        <div class="pa-3" :class="value6 ? 'active-checked' : ''">
          <v-checkbox
            v-model="value6"
            color="#C71010"
            class="mt-0"
            hide-details
            label="อื่นๆ"
            value="อื่นๆ"
            @change="onSelect('อื่นๆ', $event)"
          ></v-checkbox>

          <v-textarea
            v-if="value6"
            v-model="form.question_5_other"
            :rules="[
              (v) => !!v || 'กรุณากรอกข้อมูล',
              (v) =>
                (v && v.length <= 150) || 'กรุณากรอกข้อมูลไม่เกิน 150 ตัวอักษร',
            ]"
            rows="3"
            color="#000"
            placeholder="โปรดระบุ"
            outlined
          ></v-textarea>
        </div>

        <div class="px-3 pb-3">
          <span v-if="!isValid" style="color: #c71010" class="font-12">
            กรุณาเลือกอย่างน้อย 1 ตัวเลือก
          </span>
        </div>
      </BoxCard>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import "vue-scroll-picker/dist/style.css";
import BoxCard from "../BoxCard.vue";

export default {
  name: "QuestionnaireForm",
  props: {
    isValid: {
      type: Boolean,
      default: true,
    },
  },
  components: { BoxCard },
  computed: {
    ...mapState({
      step: (state) => state.questionnaire.step,
      form: (state) => state.questionnaire.form,
    }),
  },
  data() {
    return {
      value1: false,
      value2: false,
      value3: false,
      value4: false,
      value5: false,
      value6: false,
    };
  },
  created() {
    this.setData();
  },

  methods: {
    onSelect(key, val) {
      if (key === "เพราะมี ปัญหาร่างกาย/ความกังวล") {
        key = 'เพราะมี "ปัญหาร่างกาย/ความกังวล"';
      }

      // case อื่นๆ
      if (key === "อื่นๆ") {
        if (!val) {
          this.form.question_5_other = "";
        }
      }
      if (val) {
        this.$store.commit("questionnaire/setValueStep5", val);
      } else {
        this.$store.commit("questionnaire/removeValueStep5", key);
      }

      if (this.form.question_5_select_name.length > 0) {
        this.$emit("onValid", true);
      } else {
        this.$emit("onValid", false);
      }
    },
    setData() {
      if (this.form.question_5_select_name.length > 0) {
        this.value1 = this.form.question_5_select_name.includes(
          'เพราะมี "ปัญหาร่างกาย/ความกังวล"'
        )
          ? 'เพราะมี "ปัญหาร่างกาย/ความกังวล"'
          : false;
        this.value2 = this.form.question_5_select_name.includes(
          "เพราะอยากมีอายุยืนยาว"
        )
          ? "เพราะอยากมีอายุยืนยาว"
          : false;
        this.value3 = this.form.question_5_select_name.includes(
          "แทนการตรวจสุขภาพที่โรงพยาบาล"
        )
          ? "แทนการตรวจสุขภาพที่โรงพยาบาล"
          : false;
        this.value4 = this.form.question_5_select_name.includes(
          "เพราะดูน่าสนใจ"
        )
          ? "เพราะดูน่าสนใจ"
          : false;
        this.value5 = this.form.question_5_select_name.includes(
          "เพราะครอบครัว/เพื่อนแนะนำ"
        )
          ? "เพราะครอบครัว/เพื่อนแนะนำ"
          : false;
        this.value6 = this.form.question_5_select_name.includes("อื่นๆ")
          ? "อื่นๆ"
          : false;
      }
    },
  },
};
</script>

<style lang="scss">
.step-5 {
}
</style>
