import Service from "../../services";

const state = {
  token: null,
  questionnaireDetail: null,
  step: 1,
  stepBefore: 0,
  step_8_11_no: 1,
  step_8_11_sub_no: 1,
  form: {
    // step 1
    first_name: "",
    last_name: "",
    nickname: "",
    tel: "",

    // step 2
    birthdate: "",

    // step 3
    gender: "M",

    // step 4
    postcode: "",
    province_id: "",
    province_name: "",
    district_id: "",
    district_name: "",

    // step 5
    question_5_select_name: [],
    question_5_other: "",

    // step 6
    body_problem_concern: [
      // {
      //   question_6_problem_name: "",
      //   question_6_problem_other: "",
      // },
    ],

    // step 7
    body_problem_concern_position: [
      // {
      //   problem_name: "",
      //   positions: [],
      // },
    ],

    body_problem_concern_questionnaire: [
      // {
      //   // step 8
      //   question_8_is_know: null,
      //   question_8_cause_question: null,
      //   // step 9
      //   question_9_select_name: null,
      //   question_9_last_month: null,
      //   question_9_last_week: null,
      //   // step 10
      //   question_10_wakeup: null,
      //   question_10_standing: null,
      //   question_10_walking: null,
      //   question_10_sitting: null,
      //   question_10_moving: null,
      //   question_10_sleeping: null,
      //   // step 11
      //   question_11_hospital: null,
      //   question_11_massage: null,
      //   question_11_yoga: null,
      //   question_11_fitness: null,
      //   question_11_exercise: null,
      //   question_11_exercise_other: null,
      // },
    ],

    // step 12
    question_12_often_exercise: "",
    question_12_often_exercise_week: "",
    question_12_often_exercise_month: "",

    // step 13
    question_13_smoke: "",
    question_13_smoke_day: "",
    question_13_smoke_year: "",

    // step 14
    question_14_alcohol: "",
    question_14_alcohol_week: "",
    question_14_alcohol_month: "",

    // step 15
    question_15_know_powerpod: [],
    question_15_member_recommend: "",
    question_15_other: "",
  },
};

const getters = {};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setQuestionnaireDetail(state, payload) {
    console.log("setQuestionnaireDetail", payload);
    state.questionnaireDetail = payload;
  },
  setStep(state, step) {
    state.step = step;
  },
  setStepBefore(state, step) {
    state.stepBefore = step;
  },

  setTel(state, tel) {
    state.form.tel = tel;
  },
  setGender(state, gender) {
    state.form.gender = gender;
  },

  setValueStep5(state, payload) {
    state.form.question_5_select_name.push(payload);
  },
  removeValueStep5(state, payload) {
    state.form.question_5_select_name =
      state.form.question_5_select_name.filter((item) => item !== payload);
  },

  setValueStep6(state, payload) {
    // set body_problem_concern
    state.form.body_problem_concern.push({
      question_6_problem_name: payload,
      question_6_problem_other: "",
    });

    // set body_problem_concern_position
    if (
      payload == "ความเจ็บปวด" ||
      payload == "ตัวเย็นขี้หนาว" ||
      payload == "ร้อนวูบวาบ" ||
      payload == "บวม"
    ) {
      state.form.body_problem_concern_position.push({
        problem_name: payload,
        positions: [
          {
            question_7_position: null,
          },
        ],
      });
    }

    // set body_problem_concern_questionnaire
    state.form.body_problem_concern_questionnaire.push({
      problem_name: payload,
      // step 8
      question_8_is_know: null,
      question_8_cause_question: null,
      // step 9
      question_9_select_name: null,
      question_9_last_month: null,
      question_9_last_week: null,
      // step 10
      question_10_wakeup: null,
      question_10_standing: null,
      question_10_walking: null,
      question_10_sitting: null,
      question_10_moving: null,
      question_10_sleeping: null,
      // step 11
      question_11_hospital: null,
      question_11_massage: null,
      question_11_yoga: null,
      question_11_fitness: null,
      question_11_exercise: null,
      question_11_exercise_other: null,
    });
  },
  removeValueStep6(state, payload) {
    // remove body_problem_concern
    state.form.body_problem_concern = state.form.body_problem_concern.filter(
      (item) => item.question_6_problem_name !== payload
    );

    // remove body_problem_concern_position
    state.form.body_problem_concern_position =
      state.form.body_problem_concern_position.filter(
        (item) => item.problem_name !== payload
      );

    // remove body_problem_concern_questionnaire
    state.form.body_problem_concern_questionnaire =
      state.form.body_problem_concern_questionnaire.filter(
        (item) => item.problem_name !== payload
      );
  },
  setOtherStep6(state, { key, val }) {
    // find array index
    const index = state.form.body_problem_concern.findIndex(
      (item) => item.question_6_problem_name === key
    );
    // set val
    state.form.body_problem_concern[index].question_6_problem_other = val;
  },

  setValueStep7(state, { index, index2, val }) {
    // find array index
    state.form.body_problem_concern_position[index].positions[
      index2
    ].question_7_position = val;
  },
  addPositionStep7(state, index) {
    // find array index
    state.form.body_problem_concern_position[index].positions.push({
      question_7_position: null,
    });
  },
  removePositionStep7(state, { index, positionIndex }) {
    console.log(index, positionIndex);
    // find array index
    state.form.body_problem_concern_position[index].positions =
      state.form.body_problem_concern_position[index].positions.filter(
        (item, i) => i !== positionIndex
      );
  },

  setValueStep8(state, { key, index, val }) {
    if (val === "ไม่ทราบ") {
      state.form.body_problem_concern_questionnaire[
        index
      ].question_8_cause_question = null;
    }
    state.form.body_problem_concern_questionnaire[index][key] = val;
  },
  addStepSubNo(state) {
    state.step_8_11_sub_no++;
  },
  removeStepSubNo(state) {
    state.step_8_11_sub_no--;
  },

  setValueStep15(state, payload) {
    state.form.question_15_know_powerpod.push(payload);
  },
  removeValueStep15(state, payload) {
    state.form.question_15_know_powerpod =
      state.form.question_15_know_powerpod.filter((item) => item !== payload);
  },

  clearData() {
    // clear vuex data
    localStorage.removeItem("vuex");
  },
};

const actions = {
  async checkLink({ commit }, payload) {
    try {
      const { data } = await Service.questionnaire.checkLink(payload);
      // console.log(data);
      if (data.status === 200) {
        commit("setToken", payload.token);
        if (data.data) {
          commit("setTel", data.data.tel);
          commit("setQuestionnaireDetail", data.data);
        }
      }
      return data;
    } catch (error) {
      return error;
    }
  },

  async saveQuestionnaire({ state }, payload) {
    try {
      const { data } = await Service.questionnaire.saveQuestionnaire(
        state.token,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
