<template>
  <div class="main-step px-6">
    <div class="canvas"></div>
    <v-layout justify-end>
      <v-flex shrink>
        <v-img src="../assets/logo.png" width="186" contain></v-img>
      </v-flex>
    </v-layout>

    <div class="mb-4">
      <box-card backgroundColor="#55C5C8">
        <v-layout class="py-2 px-6" justify-space-between>
          <v-flex shrink class="mt-n12">
            <v-img
              src="../assets/images/step-16.png"
              width="95"
              contain
            ></v-img>
          </v-flex>
          <v-flex shrink style="color: #fff">
            <div class="font-24 font-w-600 mb-n2">Questionnaire</div>
            <div class="font-36">แบบสอบถาม</div>
          </v-flex>
        </v-layout>
      </box-card>
    </div>

    <div style="padding-bottom: 3rem" v-if="form">
      <!-- card 1 -->
      <box-card class="mb-5">
        <!-- info -->
        <div class="py-3 px-4">
          <v-layout justify-end class="mb-n2">
            <v-flex shrink>
              <v-img
                src="../assets/images/step1-info.png"
                width="60"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs4 class="py-2">
              <div class="font-20 font-w-600">1.ชื่อ</div>
            </v-flex>
            <v-flex xs8 class="py-2">
              <div class="font-20 font-w-400">
                {{ form.first_name }}
              </div>
            </v-flex>

            <v-flex xs4 class="py-2">
              <div class="font-20 font-w-600">นามสกุล</div>
            </v-flex>
            <v-flex xs8 class="py-2">
              <div class="font-20 font-w-400">
                {{ form.last_name }}
              </div>
            </v-flex>

            <v-flex xs4 class="py-2">
              <div class="font-20 font-w-600">ชื่อเล่น</div>
            </v-flex>
            <v-flex xs8 class="py-2">
              <div class="font-20 font-w-400">
                {{ form.nickname }}
              </div>
            </v-flex>
          </v-layout>
        </div>
        <v-divider />

        <!-- 2.วันเกิด -->
        <div class="py-3 px-4">
          <v-layout wrap justify-space-between align-center>
            <v-flex shrink class="py-2">
              <div class="font-20 font-w-600">2.วันเกิด</div>
              <div class="font-20 font-w-400">
                {{ formatDate(form.birthdate) }}
              </div>
            </v-flex>
            <v-flex shrink class="py-2">
              <v-img
                src="../assets/images/step2-birthdate.png"
                width="60"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </div>
        <v-divider />

        <!-- 3.เพศ -->
        <div class="py-3 px-4">
          <v-layout wrap justify-space-between align-center>
            <v-flex shrink class="py-2">
              <div class="font-20 font-w-600">3.เพศ</div>
              <div class="font-20 font-w-400">
                {{ formatGender(form.gender) }}
              </div>
            </v-flex>
            <v-flex shrink class="py-2">
              <Icon
                v-if="form.gender == 'F'"
                style="font-size: 3rem"
                icon="ph:gender-female-bold"
              />
              <Icon v-else style="font-size: 3rem" icon="ph:gender-male-bold" />
            </v-flex>
          </v-layout>
        </div>
        <v-divider />

        <!-- 4.พื้นที่อยู่อาศัย -->
        <div class="py-3 px-4">
          <v-layout justify-space-between align-center>
            <v-flex shrink class="py-2">
              <div class="font-20 font-w-600">4.พื้นที่อยู่อาศัย</div>
              <div class="font-20 font-w-400">
                {{ form.postcode }} {{ form.province_name }},
                {{ form.district_name }}
              </div>
            </v-flex>
            <v-flex shrink class="py-2">
              <v-img
                src="../assets/images/step4-home.png"
                width="60"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </div>
        <v-divider />

        <!-- 5.สาเหตุที่คิดจะ "ตรวจอายุ ร่างกาย" ในครั้งนี้คืออะไร  -->
        <div class="py-3 px-4">
          <v-layout justify-space-between align-center>
            <v-flex shrink class="py-2">
              <div class="font-20 font-w-600">
                5.สาเหตุที่คิดจะ "ตรวจอายุ ร่างกาย" ในครั้งนี้คืออะไร?
              </div>
            </v-flex>
            <v-flex shrink class="py-2">
              <v-img
                src="../assets/images/step5.png"
                width="60"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
          <ul>
            <li
              v-for="(item, i) in form.question_5_select_name"
              :key="i"
              class="font-20 font-w-400"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </box-card>

      <!-- card 2 -->
      <box-card
        class="mb-5"
        v-for="(item, i) in form.body_problem_concerns"
        :key="i"
      >
        <!-- 6.ปัญหาร่างกาย/ ความกังวล"ที่ต้องการ แก้ไขคืออะไรบ้าง? -->
        <div class="py-3 px-4">
          <v-layout justify-space-between align-center>
            <v-flex shrink>
              <div class="font-20 font-w-600">
                6.ปัญหาร่างกาย/ความกังวล"ที่ต้องการ แก้ไขคืออะไรบ้าง?
              </div>
            </v-flex>
            <v-flex shrink class="py-2">
              <v-img
                src="../assets/images/step6-problem.png"
                width="60"
                contain
              ></v-img>
            </v-flex>
          </v-layout>

          <!-- หัวข้อปัญหา -->
          <div class="mb-3">
            <v-btn
              color="#C71010"
              style="border-radius: 35px"
              height="40px"
              depressed
              dark
            >
              <div class="font-20 font-w-400">
                {{ item.question_6_problem_name }}
              </div>
            </v-btn>
          </div>

          <!-- ถ้าเลือกปัญหาข้อ 1-4 ให้แสดง problem position (ตำแหน่งที่มีอาการ/ปัญหาคือบริเวณใด?)  -->
          <v-layout>
            <v-flex xs8>
              <div class="font-20 font-w-600">
                7.ตำแหน่งที่มีอาการ /ปัญหาคือบริเวณใด?
              </div>
              <ul>
                <li
                  v-for="(text, d) in item.body_problem_concern_position"
                  :key="d"
                  class="font-20 font-w-400"
                >
                  {{ text.question_7_position }}
                </li>
              </ul>
            </v-flex>
            <v-flex xs4 style="position: relative">
              <div class="body_model">
                <img
                  src="../assets/images/body-position/body.png"
                  widht="122.45px"
                  height="113.94px"
                  contain
                />

                <div
                  class="part_position"
                  v-for="(body_parts, i) in body_parts"
                  :key="i"
                >
                  <img
                    v-if="
                      checkShowImage(
                        item.body_problem_concern_position,
                        body_parts.name
                      )
                    "
                    :src="
                      require(`../assets/images/body-position/${body_parts.image}.png`)
                    "
                    widht="122.45px"
                    height="113.94px"
                    alt=""
                  />
                </div>
              </div>
            </v-flex>
          </v-layout>
        </div>
        <v-divider />

        <!-- 8.คุณพอจะทราบสาเหตุที่ทำให้เกิดอาการ/ปัญหาออกหรือไม่? -->
        <div class="py-3 px-4">
          <v-layout wrap justify-space-between align-center>
            <v-flex shrink class="py-2">
              <div class="font-20 font-w-600">
                8.คุณพอจะทราบสาเหตุที่ทำให้เกิดอาการ /ปัญหาออกหรือไม่?
              </div>
              <div class="font-20 font-w-400">
                {{
                  item.body_problem_concern_questionnaire
                    ? item.body_problem_concern_questionnaire.question_8_is_know
                    : ""
                }}
              </div>
              <div
                class="font-20 font-w-400"
                v-if="
                  item.body_problem_concern_questionnaire &&
                  item.body_problem_concern_questionnaire.question_8_is_know ===
                    'ทราบ'
                "
              >
                {{
                  item.body_problem_concern_questionnaire
                    .question_8_case_question
                }}
              </div>
            </v-flex>
          </v-layout>
        </div>
        <v-divider />

        <!-- 9.คุณเริ่มรู้สึกถึงอาการ/ปัญหา ตั้งแต่เมื่อใด? -->
        <div class="py-3 px-4">
          <v-layout justify-space-between align-center>
            <v-flex shrink class="py-2">
              <div class="font-20 font-w-600">
                9.คุณเริ่มรู้สึกถึงอาการ/ปัญหา ตั้งแต่เมื่อใด?
              </div>
              <div class="font-20 font-w-400">
                {{
                  item.body_problem_concern_questionnaire
                    ? checkSymptom(item.body_problem_concern_questionnaire)
                    : ""
                }}
              </div>
            </v-flex>
            <v-flex shrink class="py-2">
              <v-img
                src="../assets/images/calendar.png"
                width="60"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </div>
        <v-divider />

        <!-- 10.อาการ/ปัญหาเกิดขึ้นในเวลาใด? -->
        <div class="py-3 px-4">
          <v-layout justify-space-between align-center>
            <v-flex shrink class="py-2">
              <div class="font-20 font-w-600">
                10.อาการ/ปัญหาเกิดขึ้นในเวลาใด?
              </div>
              <ul
                class="font-18 font-w-400"
                v-if="item.body_problem_concern_questionnaire"
              >
                <li>
                  ตอนที่ตื่นนอน ({{
                    item.body_problem_concern_questionnaire.question_10_wakeup
                  }})
                </li>
                <li>
                  ตอนที่ยืนอยู่({{
                    item.body_problem_concern_questionnaire
                      .question_10_standing
                  }})
                </li>
                <li>
                  ตอนที่เดินอยู่({{
                    item.body_problem_concern_questionnaire.question_10_walking
                  }})
                </li>
                <li>
                  ตอนที่นั่งอยู่({{
                    item.body_problem_concern_questionnaire.question_10_sitting
                  }})
                </li>
                <li>
                  ตอนที่เคลื่อนไหวบางอิริยาบท({{
                    item.body_problem_concern_questionnaire.question_10_moving
                  }})
                </li>
                <li>
                  ตอนที่นอนอยู่({{
                    item.body_problem_concern_questionnaire
                      .question_10_sleeping
                  }})
                </li>
              </ul>
            </v-flex>
          </v-layout>
        </div>
        <v-divider />

        <!-- 11.ที่ผ่านมาคุณทำอะไรไปบ้าง เพื่อแก้ไขอาการ/ปัญหา?  -->
        <div class="py-3 px-4">
          <v-layout justify-space-between align-center>
            <v-flex shrink class="py-2">
              <div class="font-20 font-w-600">
                11.ที่ผ่านมาคุณทำอะไรไปบ้าง เพื่อแก้ไขอาการ/ปัญหา?
              </div>
              <ul
                class="font-18 font-w-400"
                v-if="item.body_problem_concern_questionnaire"
              >
                <li>
                  ตรวจที่โรงพยาบาล/คลินิก({{
                    item.body_problem_concern_questionnaire
                      .question_11_hospital
                  }})
                </li>
                <li>
                  นวด({{
                    item.body_problem_concern_questionnaire.question_11_massage
                  }})
                </li>
                <li>
                  เข้าคลาสโยคะ/พิลาทิส({{
                    item.body_problem_concern_questionnaire.question_11_yoga
                  }})
                </li>
                <li>
                  ไปฟิตเนส({{
                    item.body_problem_concern_questionnaire.question_11_fitness
                  }})
                </li>
                <li>
                  ออกกำลังกายด้วยตัวเอง({{
                    item.body_problem_concern_questionnaire
                      .question_11_exercise
                  }})
                </li>
                <li
                  v-if="
                    item.body_problem_concern_questionnaire
                      .question_11_exercise_other
                  "
                >
                  อื่นๆ({{
                    item.body_problem_concern_questionnaire
                      .question_11_exercise_other
                  }})
                </li>
              </ul>
            </v-flex>
          </v-layout>
        </div>
      </box-card>

      <!-- card 3 -->
      <box-card>
        <!-- 12.คุณมักจะออกกำลังกายหรือไม่?  -->
        <div class="py-3 px-4">
          <v-layout justify-space-between align-start>
            <v-flex shrink class="py-2">
              <div class="font-20 font-w-600">
                12.คุณมักจะออกกำลังกายหรือไม่?
              </div>
              <div class="font-20">
                {{ form.question_12_often_exercise }}
              </div>
              <div
                class="font-20"
                v-if="form.question_12_often_exercise !== 'ไม่ได้ออกกำลังกาย'"
              >
                {{ checkExercise(form) }}
              </div>
            </v-flex>
            <v-flex shrink class="py-2">
              <v-img
                src="../assets/images/step-12.png"
                width="60"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </div>
        <v-divider />

        <!-- 13.คุณสูบบุหรี่หรือไม่??  -->
        <div class="py-3 px-4">
          <v-layout justify-space-between align-start>
            <v-flex shrink class="py-2">
              <div class="font-20 font-w-600">13.คุณสูบบุหรี่หรือไม่??</div>
              <div class="font-20">
                {{ form.question_13_smoke }}
              </div>
              <div class="font-20" v-if="form.question_13_smoke !== 'ไม่สูบ'">
                {{ checkSmoke(form) }}
              </div>
            </v-flex>
            <v-flex shrink class="py-2">
              <v-img
                src="../assets/images/step-13.png"
                width="60"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </div>
        <v-divider />

        <!-- 14.คุณดื่มเครื่องดื่มผสมแอลกอฮอล์หรือไม่?  -->
        <div class="py-3 px-4">
          <v-layout justify-space-between align-start>
            <v-flex shrink class="py-2">
              <div class="font-20 font-w-600">
                14.คุณดื่มเครื่องดื่มผสมแอลกอฮอล์หรือไม่?
              </div>
              <div class="font-20">
                {{ form.question_14_alcohol }}
              </div>
              <div
                class="font-20"
                v-if="form.question_14_alcohol !== 'ไม่ดื่ม'"
              >
                {{ checkDrink(form) }}
              </div>
            </v-flex>
            <v-flex shrink class="py-2">
              <v-img
                src="../assets/images/step-14.png"
                width="60"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </div>
        <v-divider />

        <!-- 15.คุณรู้จัก Powerpod จากช่องทางใด?  -->
        <div class="py-3 px-4">
          <div class="font-20 font-w-600">
            15.คุณรู้จัก Powerpod จากช่องทางใด?
          </div>
          <div
            class="font-20"
            v-for="(
              question_15_know_powerpod, q
            ) in form.question_15_know_powerpod"
            :key="q"
          >
            {{ question_15_know_powerpod }}
            <div v-if="question_15_know_powerpod == 'สมาชิก Powerpod แนะนำ'">
              {{ form.question_15_member_recommend }}
            </div>
            <div v-if="question_15_know_powerpod == 'อื่นๆ'">
              {{ form.question_15_other }}
            </div>
          </div>
        </div>
      </box-card>
    </div>

    <v-snackbar elevation="24" width="400" v-model="snackbar" color="#55C5C8">
      <v-layout align-center justify-space-between>
        <v-flex shrink class="font-w-500 font-18">คุณได้ทำแบบสอบถามแล้ว</v-flex>
        <v-flex shrink>
          <v-btn icon color="#fff" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <!-- <div>
      <pre>
            {{ questionnaireDetail.questionnaire.body_problem_concerns }}
        </pre
      >
    </div> -->
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import BoxCard from "@/components/BoxCard.vue";
import { mapState } from "vuex";

import { body_parts } from "../Constants";

export default {
  components: { BoxCard, Icon },
  computed: {
    ...mapState({
      questionnaireDetail: (state) => state.questionnaire.questionnaireDetail,
    }),
    form() {
      return this.questionnaireDetail.questionnaire;
    },
  },
  data() {
    return {
      snackbar: true,
      body_parts,
    };
  },
  created() {
    console.log(this.questionnaireDetail.questionnaire);
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },

    formatGender(gender) {
      if (gender === "M") {
        return "ชาย";
      } else {
        return "หญิง";
      }
    },

    checkShowImage(positions, key) {
      let isShow = false;
      positions.forEach((e) => {
        if (e.question_7_position == key) {
          isShow = true;
        }
      });
      return isShow;
    },

    checkSymptom(item) {
      switch (item.question_9_select_name) {
        case "เดือนที่แล้ว":
          return item.question_9_last_month + " เดือนที่แล้ว";
        case "สัปดาห์ที่ผ่านมา":
          return item.question_9_last_week + " สัปดาห์ที่ผ่านมา";
        default:
          return item.question_9_select_name;
      }
    },

    checkExercise(item) {
      if (item.question_12_often_exercise === "ทำประจำ ประมาณ") {
        return item.question_12_often_exercise_week + " วัน/สัปดาห์";
      } else {
        return item.question_12_often_exercise_month + " วัน/เดือน";
      }
    },

    checkSmoke(item) {
      if (item.question_13_smoke === "สูบ ประมาณ") {
        return item.question_13_smoke_day + " มวน/วัน";
      } else {
        return item.question_13_smoke_year + " ปีที่แล้ว";
      }
    },

    checkDrink(item) {
      if (item.question_14_alcohol === "ดื่ม ประมาณ") {
        return item.question_14_alcohol_week + " วัน/สัปดาห์";
      } else {
        return item.question_14_alcohol_month + " วัน/เดือน";
      }
    },
  },
};
</script>

<style></style>
