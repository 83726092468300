<template>
  <div class="step-5">
    <v-layout justify-center>
      <v-flex shrink>
        <v-img
          src="../../assets/images/step6-problem.png"
          height="120"
          contain
        ></v-img>
      </v-flex>
    </v-layout>

    <div class="step-title text-center my-4">
      "ปัญหาร่างกาย/ <br />
      ความกังวล"ที่ต้องการ <br />
      แก้ไขคืออะไรบ้าง?
    </div>

    <div class="font-20 mb-6 text-center" style="color: #a3a3a3">
      (*แม้จะเป็นโรคไม่ติดต่อเรื้อรัง) <br />
      (*เลือกตอบได้หลายข้อสูงสุด 3 ข้อ)
    </div>

    <div style="padding-bottom: 8rem">
      <BoxCard class="custom_checkbox_radio">
        <div class="pa-3" :class="checkboxForm.value1 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value1"
            class="mt-0"
            hide-details
            color="#C71010"
            label="ความเจ็บปวด"
            value="ความเจ็บปวด"
            @change="onSelect('ความเจ็บปวด', $event)"
            :disabled="
              checkboxForm.value1 !== 'ความเจ็บปวด' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value2 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value2"
            color="#C71010"
            class="mt-0"
            hide-details
            label="ตัวเย็นขี้หนาว"
            value="ตัวเย็นขี้หนาว"
            @change="onSelect('ตัวเย็นขี้หนาว', $event)"
            :disabled="
              checkboxForm.value2 !== 'ตัวเย็นขี้หนาว' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value3 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value3"
            color="#C71010"
            class="mt-0"
            hide-details
            label="ร้อนวูบวาบ"
            value="ร้อนวูบวาบ"
            @change="onSelect('ร้อนวูบวาบ', $event)"
            :disabled="
              checkboxForm.value3 !== 'ร้อนวูบวาบ' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value4 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value4"
            color="#C71010"
            class="mt-0"
            hide-details
            label="บวม"
            value="บวม"
            @change="onSelect('บวม', $event)"
            :disabled="
              checkboxForm.value4 !== 'บวม' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value5 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value5"
            color="#C71010"
            class="mt-0"
            hide-details
            label="วิงเวียนศีรษะ"
            value="วิงเวียนศีรษะ"
            @change="onSelect('วิงเวียนศีรษะ', $event)"
            :disabled="
              checkboxForm.value5 !== 'วิงเวียนศีรษะ' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value6 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value6"
            color="#C71010"
            class="mt-0"
            hide-details
            label="ใจสั่น"
            value="ใจสั่น"
            @change="onSelect('ใจสั่น', $event)"
            :disabled="
              checkboxForm.value6 !== 'ใจสั่น' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value7 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value7"
            color="#C71010"
            class="mt-0"
            hide-details
            label="หายใจถี่"
            value="หายใจถี่"
            @change="onSelect('หายใจถี่', $event)"
            :disabled="
              checkboxForm.value7 !== 'หายใจถี่' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value8 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value8"
            color="#C71010"
            class="mt-0"
            hide-details
            label="อ่อนเพลีย"
            value="อ่อนเพลีย"
            @change="onSelect('อ่อนเพลีย', $event)"
            :disabled="
              checkboxForm.value8 !== 'อ่อนเพลีย' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value9 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value9"
            color="#C71010"
            class="mt-0"
            hide-details
            label="หูอื้อหูมีเสียง"
            value="หูอื้อหูมีเสียง"
            @change="onSelect('หูอื้อหูมีเสียง', $event)"
            :disabled="
              checkboxForm.value9 !== 'หูอื้อหูมีเสียง' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value10 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value10"
            color="#C71010"
            class="mt-0"
            hide-details
            label="น้ำหนักเพิ่มขึ้น"
            value="น้ำหนักเพิ่มขึ้น"
            @change="onSelect('น้ำหนักเพิ่มขึ้น', $event)"
            :disabled="
              checkboxForm.value10 !== 'น้ำหนักเพิ่มขึ้น' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value11 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value11"
            color="#C71010"
            class="mt-0"
            hide-details
            label="ความดันโลหิตสูง"
            value="ความดันโลหิตสูง"
            @change="onSelect('ความดันโลหิตสูง', $event)"
            :disabled="
              checkboxForm.value11 !== 'ความดันโลหิตสูง' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value12 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value12"
            color="#C71010"
            class="mt-0"
            hide-details
            label="ความดันโลหิตต่ำ"
            value="ความดันโลหิตต่ำ"
            @change="onSelect('ความดันโลหิตต่ำ', $event)"
            :disabled="
              checkboxForm.value12 !== 'ความดันโลหิตต่ำ' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value13 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value13"
            color="#C71010"
            class="mt-0"
            hide-details
            label="ความผิดปกติของการนอน"
            value="ความผิดปกติของการนอน"
            @change="onSelect('ความผิดปกติของการนอน', $event)"
            :disabled="
              checkboxForm.value13 !== 'ความผิดปกติของการนอน' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value14 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value14"
            color="#C71010"
            class="mt-0"
            hide-details
            label="ท้องผูก"
            value="ท้องผูก"
            @change="onSelect('ท้องผูก', $event)"
            :disabled="
              checkboxForm.value14 !== 'ท้องผูก' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value15 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value15"
            color="#C71010"
            class="mt-0"
            hide-details
            label="ปัสสาวะบ่อย"
            value="ปัสสาวะบ่อย"
            @change="onSelect('ปัสสาวะบ่อย', $event)"
            :disabled="
              checkboxForm.value15 !== 'ปัสสาวะบ่อย' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value16 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value16"
            color="#C71010"
            class="mt-0"
            hide-details
            label="อื่นๆ"
            value="อื่นๆ"
            @change="onSelect('อื่นๆ', $event)"
            :disabled="
              checkboxForm.value16 !== 'อื่นๆ' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>

          <v-textarea
            v-if="checkboxForm.value16"
            class="mt-2"
            v-model="checkboxForm.value16_other"
            :rules="[
              (v) => !!v || 'กรุณากรอกข้อมูล',
              (v) =>
                (v && v.length <= 150) || 'กรุณากรอกข้อมูลไม่เกิน 150 ตัวอักษร',
            ]"
            rows="3"
            color="#000"
            background-color="#fff"
            placeholder="โปรดระบุ"
            outlined
            @input="onInput('อื่นๆ', $event)"
          ></v-textarea>
        </div>

        <v-divider />

        <div class="pa-3" :class="checkboxForm.value17 ? 'active-checked' : ''">
          <v-checkbox
            v-model="checkboxForm.value17"
            color="#C71010"
            class="mt-0"
            hide-details
            label="โรคเรื้อรัง"
            value="โรคเรื้อรัง"
            @change="onSelect('โรคเรื้อรัง', $event)"
            :disabled="
              checkboxForm.value17 !== 'โรคเรื้อรัง' &&
              form.body_problem_concern.length >= 3
            "
          ></v-checkbox>

          <v-textarea
            v-if="checkboxForm.value17"
            class="mt-2"
            v-model="checkboxForm.value17_other"
            :rules="[
              (v) => !!v || 'กรุณากรอกข้อมูล',
              (v) =>
                (v && v.length <= 150) || 'กรุณากรอกข้อมูลไม่เกิน 150 ตัวอักษร',
            ]"
            rows="3"
            color="#000"
            background-color="#fff"
            placeholder="โปรดระบุ"
            outlined
            @input="onInput('โรคเรื้อรัง', $event)"
          ></v-textarea>
        </div>

        <div class="px-3 pb-3" v-if="!isValid">
          <span style="color: red" class="font-12">
            กรุณาเลือกอย่างน้อย 1 ตัวเลือก
          </span>
        </div>
      </BoxCard>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import "vue-scroll-picker/dist/style.css";
import BoxCard from "../BoxCard.vue";

export default {
  name: "QuestionnaireForm",
  props: {
    isValid: {
      type: Boolean,
      default: true,
    },
  },
  components: { BoxCard },
  computed: {
    ...mapState({
      step: (state) => state.questionnaire.step,
      form: (state) => state.questionnaire.form,
    }),
  },
  data() {
    return {
      checkboxForm: {
        value1: false,
        value2: false,
        value3: false,
        value4: false,
        value5: false,
        value6: false,
        value7: false,
        value8: false,
        value9: false,
        value10: false,
        value11: false,
        value12: false,
        value13: false,
        value14: false,
        value15: false,
        value16: false,
        value16_other: "",
        value17: false,
        value17_other: "",
      },
    };
  },
  mounted() {
    this.setData();
  },
  methods: {
    onSelect(key, val) {
      // case อื่นๆ และ โรคเรื้อรัง ต้องมีการกรอกข้อมูล ถ้าไม่มีให้ลบออก
      if (key === "อื่นๆ") {
        if (!val) {
          this.value16_other = "";
          this.onInput(key, "");
        }
      } else if (key === "โรคเรื้อรัง") {
        if (!val) {
          this.value17_other = "";
          this.onInput(key, "");
        }
      }

      // set value
      if (val) {
        this.$store.commit("questionnaire/setValueStep6", key);
      } else {
        this.$store.commit("questionnaire/removeValueStep6", key);
      }

      // check valid
      if (this.form.body_problem_concern.length > 0) {
        this.$emit("onValid", true);
      } else {
        this.$emit("onValid", false);
      }
    },
    onInput(key, val) {
      this.$store.commit("questionnaire/setOtherStep6", {
        key,
        val,
      });
    },
    setData() {
      if (this.form.body_problem_concern.length > 0) {
        const problems = [
          "ความเจ็บปวด",
          "ตัวเย็นขี้หนาว",
          "ร้อนวูบวาบ",
          "บวม",
          "วิงเวียนศีรษะ",
          "ใจสั่น",
          "หายใจถี่",
          "อ่อนเพลีย",
          "หูอื้อหูมีเสียง",
          "น้ำหนักเพิ่มขึ้น",
          "ความดันโลหิตสูง",
          "ความดันโลหิตต่ำ",
          "ความผิดปกติของการนอน",
          "ท้องผูก",
          "ปัสสาวะบ่อย",
          "อื่นๆ",
          "โรคเรื้อรัง",
        ];

        this.form.body_problem_concern.forEach((e) => {
          problems.forEach((p) => {
            if (e.question_6_problem_name == p) {
              this.checkboxForm[`value${problems.indexOf(p) + 1}`] =
                e.question_6_problem_name;

              if (e.question_6_problem_name === "อื่นๆ") {
                this.checkboxForm.value16_other = e.question_6_problem_other;
              }

              if (e.question_6_problem_name === "โรคเรื้อรัง") {
                this.checkboxForm.value17_other = e.question_6_problem_other;
              }
            }
          });
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
