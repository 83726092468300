<template>
  <div
    style="
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <div class="font-40">Page not found!</div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
