// local
// export const domain = "http://127.0.0.1:8000/";

// server
export const domain = "https://powerpodfitness-backend.ksta.co/";
// export const domain = "https://systemapi.powerpodfitness.com/";

export const body_parts = [
  { name: "ศรีษะ", image: "head" },
  { name: "สะบัก", image: "shoulder" },
  { name: "ไหล่", image: "shoulder-2" },
  { name: "หลังส่วนบน", image: "upper-back" },
  { name: "หลังส่วนล่าง", image: "lower-back" },
  { name: "เอว", image: "waist" },
  { name: "สะโพก", image: "hip" },
  { name: "แขน", image: "arm" },
  { name: "นิ้วมือ", image: "finger" },
  { name: "ข้อพับ", image: "joint" },
  { name: "ข้อมือ", image: "wrist" },
  { name: "หน้าท้อง", image: "abdomen" },
  { name: "หน้าอก", image: "chest" },
  { name: "หลังขา", image: "behind-leg" },
  { name: "ต้นขา", image: "thigh" },
  { name: "หัวเข่า", image: "knee" },
  { name: "หน้าแข้ง", image: "shin" },
  { name: "น่อง", image: "calf" },
  { name: "ข้อเท้า", image: "ankle" },
  { name: "นิ้วเท้า", image: "toenail" },
];

export const body_positions = [
  { no: 1, name: "ศรีษะ", image: "head" },
  { no: 2, name: "สะบัก", image: "shoulder" },
  { no: 3, name: "ไหล่", image: "shoulder-2" },
  { no: 4, name: "หลังส่วนบน", image: "upper-back" },
  { no: 5, name: "หลังส่วนล่าง", image: "lower-back" },
  { no: 6, name: "เอว", image: "waist" },
  { no: 7, name: "สะโพก", image: "hip" },
  { no: 8, name: "แขน", image: "arm" },
  { no: 9, name: "นิ้วมือ", image: "finger" },
  { no: 10, name: "ข้อพับ", image: "joint" },
  { no: 11, name: "ข้อมือ", image: "wrist" },
  { no: 12, name: "หน้าท้อง", image: "abdomen" },
  { no: 13, name: "หน้าอก", image: "chest" },
  { no: 14, name: "หลังขา", image: "behind-leg" },
  { no: 15, name: "ต้นขา", image: "thigh" },
  { no: 16, name: "หัวเข่า", image: "knee" },
  { no: 17, name: "หน้าแข้ง", image: "shin" },
  { no: 18, name: "น่อง", image: "calf" },
  { no: 19, name: "ข้อเท้า", image: "ankle" },
  { no: 20, name: "นิ้วเท้า", image: "toenail" },
];
