import axios from "axios";
import { domain } from "../Constants";

const http = new axios.create({
  baseURL: domain,
});

http.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
    return Promise.reject(err);
  }
);

const httpMethod = {
  get(url, data) {
    return http.get(url, data);
  },
  post(url, data) {
    return http.post(url, data);
  },
  put(url, data) {
    return http.put(url, data);
  },
  delete(url, data) {
    return http.delete(url, data);
  },
};

export default httpMethod;
