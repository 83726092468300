<template>
  <div class="step-8">
    <v-form ref="formStep8" v-model="validStep8" lazy-validation>
      <div
        v-for="(item, i) in form.body_problem_concern_questionnaire"
        :key="i"
      >
        <div v-if="step_8_11_no === i + 1">
          <v-layout justify-center align-center pt-2>
            <v-flex shrink>
              <v-btn
                height="39px"
                depressed
                dark
                style="border-radius: 35px"
                color="#C71010"
              >
                <span class="font-24 font-w-600">
                  {{ item.problem_name }} ( {{ i + 1 }} /
                  {{ form.body_problem_concern_questionnaire.length }})
                </span>
              </v-btn>
            </v-flex>
          </v-layout>

          <div v-for="x in 4" :key="x">
            <div v-if="step_8_11_sub_no == 1 && x == 1">
              <Step8 :indexAt="i" />
            </div>
            <div v-if="step_8_11_sub_no == 2 && x == 2">
              <Step9 :indexAt="i" />
            </div>
            <div v-if="step_8_11_sub_no == 3 && x == 3">
              <Step10 :indexAt="i" />
            </div>
            <div v-if="step_8_11_sub_no == 4 && x == 4">
              <Step11 :indexAt="i" />
            </div>
          </div>

          <div class="px-3 pb-3 text-center mb-6" v-if="!isValid">
            <span style="color: red" class="font-12">
              กรุณาเลือกตำแหน่งที่มีอาการ/ปัญหาคือบริเวณใด
            </span>
          </div>
        </div>
      </div>

      <v-layout class="btn-step8">
        <v-flex shrink>
          <v-btn
            v-if="step > 1"
            style="border-radius: 30px"
            height="70"
            width="125"
            color="#F3F3F3"
            dark
            depressed
            @click="backStep()"
          >
            <span class="font-26 font-w-600" style="color: #a3a3a3">
              ย้อนกลับ
            </span>
          </v-btn>
        </v-flex>
        <v-spacer />
        <v-flex shrink>
          <v-btn
            style="border-radius: 30px"
            height="70"
            width="172"
            color="#000"
            :dark="step !== 15"
            depressed
            :disabled="step === 15"
            @click="nextStep()"
          >
            <span class="font-32 font-w-600">ถัดไป </span>
            <Icon
              style="font-size: 3rem"
              icon="material-symbols:navigate-next"
            />
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import { mapState } from "vuex";
import "vue-scroll-picker/dist/style.css";
import Step8 from "./Step8.vue";
import Step9 from "./Step9.vue";
import Step10 from "./Step10.vue";
import Step11 from "./Step11.vue";

export default {
  name: "QuestionnaireForm",

  components: {
    Icon,
    Step8,
    Step9,
    Step10,
    Step11,
  },

  data() {
    return {
      isValid: true,
      isSkip: false,
      validStep8: true,
      step_8_11_no: 1,
      step_8_11_sub_no: 1,
    };
  },
  computed: {
    ...mapState({
      step: (state) => state.questionnaire.step,
      form: (state) => state.questionnaire.form,
      // step_8_11_no: (state) => state.questionnaire.step_8_11_no,
      // step_8_11_sub_no: (state) => state.questionnaire.step_8_11_sub_no,
    }),
  },

  created() {
    // set default step when refresh page
    this.step_8_11_no = 1;
    this.step_8_11_sub_no = 1;
    this.$store.commit("questionnaire/setStep", 8);
    this.$store.commit("questionnaire/setStepBefore", 7);

    if (this.form.body_problem_concern_questionnaire.length > 0) {
      return;
    }

    // let arr = [];
    // // เลือกมาแค่ question_6_problem_name ที่อยู่ใน array body_problem_concern = "ความเจ็บปวด, ตัวเย็นขี้หนาว, ร้อนวูบวาบ, บวม"
    // this.form.body_problem_concern.forEach((e) => {
    //   if (
    //     e.question_6_problem_name === "ความเจ็บปวด" ||
    //     e.question_6_problem_name === "ตัวเย็นขี้หนาว" ||
    //     e.question_6_problem_name === "ร้อนวูบวาบ" ||
    //     e.question_6_problem_name === "บวม"
    //   ) {
    //     arr.push({
    //       problem_name: e.question_6_problem_name,
    //       // step 8
    //       question_8_is_know: null,
    //       question_8_cause_question: null,
    //       // step 9
    //       question_9_select_name: null,
    //       question_9_last_month: null,
    //       question_9_last_week: null,
    //       // step 10
    //       question_10_wakeup: null,
    //       question_10_standing: null,
    //       question_10_walking: null,
    //       question_10_sitting: null,
    //       question_10_moving: null,
    //       question_10_sleeping: null,
    //       // step 11
    //       question_11_hospital: null,
    //       question_11_massage: null,
    //       question_11_yoga: null,
    //       question_11_fitness: null,
    //       question_11_exercise: null,
    //       question_11_exercise_other: null,
    //     });
    //   }
    // });
    // this.form.body_problem_concern_questionnaire = arr;
  },

  methods: {
    setStep8(value) {
      console.log("value", value);
      this.form.body_problem_concern_questionnaire.question_8_is_know = value;
      this.$store.commit("questionnaire/setValueStep8", value);
    },

    nextStep() {
      console.log("step_8_11_sub_no", this.step_8_11_sub_no);
      if (this.$refs.formStep8.validate()) {
        // ถ้ายังทำ step 8 - 11 ไม่ครบ ให้ไปทำ step 8 - 11 ต่อไป
        if (this.step_8_11_sub_no != 4) {
          this.step_8_11_sub_no++;

          // this.$store.commit("questionnaire/addStepSubNo");
          this.$store.commit("questionnaire/setStep", this.step + 1);
          this.$store.commit("questionnaire/setStepBefore", this.step - 1);
        }
        // ถ้าทำ step 8 - 11 ครบแล้ว
        else {
          // ให้เช็คว่า step 8 - 11 ทำครบเท่ากับจำนวนที่เลือกมาไหม ถ้าไม่ครบให้ไปทำ step 8 - 11 ต่อไป
          if (
            this.form.body_problem_concern_questionnaire.length !=
            this.step_8_11_no
          ) {
            this.step_8_11_sub_no = 1;
            this.step_8_11_no++;

            // this.$store.commit("questionnaire/addStepSubNo");
            this.$store.commit("questionnaire/setStep", 8);
            this.$store.commit("questionnaire/setStepBefore", 7);
          } else {
            // ถ้าทำ step 8 - 11 ครบแล้ว ให้ไป step 12
            this.$store.commit("questionnaire/setStep", 12);
            this.$store.commit("questionnaire/setStepBefore", 8);
          }
        }
      }
    },
    backStep() {
      console.log(this.step_8_11_sub_no, this.step_8_11_no);
      // เช็คว่ากด back มาถึง step 8 ข้อแรกแล้วหรือยัง ถ้าถึงแล้วให้ไป step ก่อนหน้า

      if (this.step_8_11_sub_no == 1 && this.step_8_11_no == 1) {
        console.log("back to step 6");
        // เช็คว่าก่อนจะมา step 8 มาจาก step 7 หรือ step 6 (this.form.body_problem_concern_position.length == 0 แสดงว่ามาจาก step 6)
        this.form.body_problem_concern.forEach((item) => {
          if (
            item.question_6_problem_name == "ความเจ็บปวด" ||
            item.question_6_problem_name == "ตัวเย็นขี้หนาว" ||
            item.question_6_problem_name == "ร้อนวูบวาบ" ||
            item.question_6_problem_name == "บวม"
          ) {
            this.isSkip = true;
          }
        });

        if (!this.isSkip) {
          this.$store.commit("questionnaire/setStep", 6);
          this.$store.commit("questionnaire/setStepBefore", 5);
        } else {
          this.$store.commit("questionnaire/setStep", 7);
          this.$store.commit("questionnaire/setStepBefore", 6);
        }
      }

      // ถ้ายังไม่ถึง step 8 ข้อแรก ให้ไป step ก่อนหน้า
      else {
        console.log(
          "ถ้ายังไม่ถึง step 8 ข้อแรก ให้ไป step ก่อนหน้า",
          this.step_8_11_no,
          this.step_8_11_sub_no
        );
        // ถ้ายังไม่ถึง step 8 ข้อแรก ให้ไป step ก่อนหน้า
        if (this.step_8_11_sub_no != 1) {
          this.step_8_11_sub_no--;
          // this.$store.commit("questionnaire/removeStepSubNo");
          this.$store.commit("questionnaire/setStep", this.step - 1);
          this.$store.commit("questionnaire/setStepBefore", this.step - 1);
        } else {
          console.log("xxxx", this.step_8_11_sub_no, this.step_8_11_no);
          this.step_8_11_sub_no = 4;
          this.step_8_11_no--;
          this.$store.commit("questionnaire/setStep", 11);
          this.$store.commit("questionnaire/setStepBefore", 10);
          // this.$store.commit("questionnaire/removeStepSubNo");
        }
      }
    },
  },
};
</script>

<style lang="scss">
.step-8 {
  position: relative;
  min-height: calc(100vh - 58px);

  .btn-step8 {
    position: absolute;
    width: 100%;
    bottom: 15px;
    z-index: 1;
  }
}
</style>
