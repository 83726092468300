var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-12"},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-img',{attrs:{"src":require("../../assets/images/step-14.png"),"height":"120","contain":""}})],1)],1),_vm._m(0),_c('div',{staticStyle:{"padding-bottom":"8rem"}},[_c('box-card',{staticClass:"py-3 custom_checkbox_radio"},[_c('v-radio-group',{attrs:{"rules":[(v) => !!v || 'กรุณาตอบคำถาม']},on:{"change":_vm.onChangeRadio},model:{value:(_vm.form.question_14_alcohol),callback:function ($$v) {_vm.$set(_vm.form, "question_14_alcohol", $$v)},expression:"form.question_14_alcohol"}},[_c('v-radio',{staticClass:"mb-6 px-3",attrs:{"color":"#C71010","value":"ไม่ดื่ม"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-24",staticStyle:{"color":"#000000"}},[_vm._v(" ไม่ดื่ม ")])]},proxy:true}])}),_c('v-divider'),_c('v-radio',{staticClass:"mt-6 mb-3 px-3",attrs:{"color":"#C71010","value":"ดื่ม ประมาณ"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-24",staticStyle:{"color":"#000000"}},[_vm._v(" ดื่ม ประมาณ ")])]},proxy:true}])}),_c('v-layout',{attrs:{"align-center":"","px-5":"","pb-6":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-autocomplete',{attrs:{"items":_vm.weeks,"disabled":_vm.form.question_14_alcohol != 'ดื่ม ประมาณ',"hide-details":"","menu-props":"offsetY","color":"#000","outlined":"","rules":_vm.form.question_14_alcohol == 'ดื่ม ประมาณ'
                  ? [(v) => !!v || 'กรุณาเลือกจำนวน']
                  : [],"required":""},model:{value:(_vm.form.question_14_alcohol_week),callback:function ($$v) {_vm.$set(_vm.form, "question_14_alcohol_week", $$v)},expression:"form.question_14_alcohol_week"}})],1),_c('v-flex',{staticClass:"pl-2 font-24",style:(_vm.form.question_14_alcohol != 'ดื่ม ประมาณ'
                ? { color: '#A3A3A3' }
                : { color: '#000' }),attrs:{"xs6":""}},[_vm._v(" วัน/สัปดาห์ ")])],1),_c('v-divider'),_c('v-radio',{staticClass:"mt-6 mb-3 px-3",attrs:{"color":"#C71010","value":"นาน ๆ ครั้ง ประมาณ"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-24",staticStyle:{"color":"#000000"}},[_vm._v(" นาน ๆ ครั้ง ประมาณ ")])]},proxy:true}])}),_c('v-layout',{attrs:{"align-center":"","px-5":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-autocomplete',{attrs:{"items":_vm.days,"disabled":_vm.form.question_14_alcohol != 'นาน ๆ ครั้ง ประมาณ',"hide-details":"","menu-props":"offsetY","color":"#000","outlined":"","rules":_vm.form.question_14_alcohol == 'นาน ๆ ครั้ง ประมาณ'
                  ? [(v) => !!v || 'กรุณาเลือกจำนวน']
                  : [],"required":""},model:{value:(_vm.form.question_14_alcohol_month),callback:function ($$v) {_vm.$set(_vm.form, "question_14_alcohol_month", $$v)},expression:"form.question_14_alcohol_month"}})],1),_c('v-flex',{staticClass:"pl-2 font-24",style:(_vm.form.question_14_alcohol != 'นาน ๆ ครั้ง ประมาณ'
                ? { color: '#A3A3A3' }
                : { color: '#000' }),attrs:{"xs6":""}},[_vm._v(" วัน/เดือน ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-title text-center"},[_vm._v(" คุณดื่มเครื่องดื่มผสม "),_c('br'),_vm._v(" แอลกอฮอล์หรือไม่? ")])
}]

export { render, staticRenderFns }