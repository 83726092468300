<template>
  <div class="step-12">
    <div class="step-title text-center mb-6">
      คุณรู้จัก <br />
      Powerpod <br />
      จากช่องทางใด?
    </div>

    <div style="padding-bottom: 8rem">
      <BoxCard class="custom_checkbox_radio">
        <div class="pa-3" :class="value1 ? 'active-checked' : ''">
          <v-checkbox
            v-model="value1"
            class="mt-0 pt-1"
            hide-details
            color="#C71010"
            value="สมาชิก Powerpod แนะนำ"
            @change="onSelect('สมาชิก Powerpod แนะนำ', $event)"
          >
            <template v-slot:label>
              <div
                class="d-flex justify-space-between font-24"
                style="color: #000"
              >
                สมาชิก Powerpod แนะนำ
              </div>
            </template>
          </v-checkbox>

          <div class="mt-6 mb-n4" v-if="value1">
            <div class="font-24">ชื่อสมาชิก</div>
            <text-field
              :outlined="true"
              :required="true"
              :model="form.question_15_member_recommend"
              :rules="[(v) => !!v || 'กรุณากรอกสมาชิก Powerpod ที่แนะนำ']"
              @update:data="form.question_15_member_recommend = $event"
            ></text-field>
          </div>
        </div>

        <v-divider />

        <div class="pa-3" :class="value2 ? 'active-checked' : ''">
          <v-checkbox
            v-model="value2"
            color="#C71010"
            class="mt-0 pt-0"
            hide-details
            value="เล่าปากต่อปาก  (ครอบครัว/เพื่อน ฯลฯ )"
            @change="onSelect('เล่าปากต่อปาก  (ครอบครัว/เพื่อน ฯลฯ )', $event)"
          >
            <template v-slot:label>
              <div
                class="d-flex justify-space-between font-24"
                style="color: #000; line-height: 30px"
              >
                เล่าปากต่อปาก (ครอบครัว/เพื่อน ฯลฯ)
              </div>
            </template>
          </v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="value3 ? 'active-checked' : ''">
          <v-checkbox
            v-model="value3"
            color="#C71010"
            class="mt-0 pt-0"
            hide-details
            value="เว็บไซต์"
            @change="onSelect('เว็บไซต์', $event)"
          >
            <template v-slot:label>
              <div
                class="d-flex justify-space-between font-24"
                style="color: #000"
              >
                เว็บไซต์
              </div>
            </template>
          </v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="value4 ? 'active-checked' : ''">
          <v-checkbox
            v-model="value4"
            color="#C71010"
            class="mt-0 pt-0"
            hide-details
            value="Social Media (Facebook/Line ฯลฯ)"
            @change="onSelect('Social Media (Facebook/Line ฯลฯ)', $event)"
          >
            <template v-slot:label>
              <div
                class="d-flex justify-space-between font-24"
                style="color: #000; line-height: 30px"
              >
                <div>Social Media (Facebook/Line ฯลฯ)</div>
              </div>
            </template>
          </v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="value5 ? 'active-checked' : ''">
          <v-checkbox
            v-model="value5"
            color="#C71010"
            class="mt-0 pt-0"
            hide-details
            value="ผ่านมาแล้วเห็น"
            @change="onSelect('ผ่านมาแล้วเห็น', $event)"
          >
            <template v-slot:label>
              <div
                class="d-flex justify-space-between font-24"
                style="color: #000"
              >
                ผ่านมาแล้วเห็น
              </div>
            </template>
          </v-checkbox>
        </div>

        <v-divider />

        <div class="pa-3" :class="value6 ? 'active-checked' : ''">
          <v-checkbox
            v-model="value6"
            color="#C71010"
            class="mt-0 pt-0"
            hide-details
            value="อื่นๆ"
            @change="onSelect('อื่นๆ', $event)"
          >
            <template v-slot:label>
              <div
                class="d-flex justify-space-between font-24"
                style="color: #000"
              >
                อื่นๆ
              </div>
            </template>
          </v-checkbox>

          <v-textarea
            class="mt-3"
            v-if="value6"
            v-model="form.question_15_other"
            :rules="[
              (v) => !!v || 'กรุณากรอกข้อมูล',
              (v) =>
                (v && v.length <= 150) || 'กรุณากรอกข้อมูลไม่เกิน 150 ตัวอักษร',
            ]"
            rows="3"
            color="#000"
            background-color="#fff"
            placeholder="โปรดระบุ"
            outlined
          ></v-textarea>
        </div>

        <div class="px-3 pb-3" v-if="!isValid">
          <span style="color: #c71010" class="font-12">
            กรุณาเลือกอย่างน้อย 1 ตัวเลือก
          </span>
        </div>
      </BoxCard>
    </div>
  </div>
</template>

<script>
// import { Icon } from "@iconify/vue2";
import { mapState } from "vuex";
import "vue-scroll-picker/dist/style.css";
import BoxCard from "../BoxCard.vue";
import TextField from "../TextField.vue";

export default {
  components: { BoxCard, TextField },
  // components: { Icon },
  name: "QuestionnaireForm",
  props: {
    isValid: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      days: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      weeks: [1, 2, 3, 4, 5, 6, 7],

      value1: false,
      value2: false,
      value3: false,
      value4: false,
      value5: false,
      value6: false,
    };
  },

  computed: {
    ...mapState({
      step: (state) => state.questionnaire.step,
      form: (state) => state.questionnaire.form,
    }),
  },

  created() {
    this.setData();
    window.scrollTo(0, 0);
  },

  methods: {
    onSelect(key, val) {
      // clear data case
      if (key === "สมาชิก Powerpod แนะนำ") {
        if (!val) {
          this.form.question_15_member_recommend = "";
        }
      }
      if (key === "อื่นๆ") {
        if (!val) {
          this.form.question_15_other = "";
        }
      }

      if (val) {
        this.$store.commit("questionnaire/setValueStep15", val);
      } else {
        this.$store.commit("questionnaire/removeValueStep15", key);
      }

      if (this.form.question_15_know_powerpod.length > 0) {
        this.$emit("onValid", true);
      } else {
        this.$emit("onValid", false);
      }
    },
    setData() {
      if (this.form.question_15_know_powerpod.length > 0) {
        this.value1 = this.form.question_15_know_powerpod.includes(
          "สมาชิก Powerpod แนะนำ"
        )
          ? "สมาชิก Powerpod แนะนำ"
          : false;
        this.value2 = this.form.question_15_know_powerpod.includes(
          "เล่าปากต่อปาก  (ครอบครัว/เพื่อน ฯลฯ )"
        )
          ? "เล่าปากต่อปาก  (ครอบครัว/เพื่อน ฯลฯ )"
          : false;
        this.value3 = this.form.question_15_know_powerpod.includes("เว็บไซต์")
          ? "เว็บไซต์"
          : false;
        this.value4 = this.form.question_15_know_powerpod.includes(
          "Social Media (Facebook/Line ฯลฯ)"
        )
          ? "Social Media (Facebook/Line ฯลฯ)"
          : false;
        this.value5 = this.form.question_15_know_powerpod.includes(
          "ผ่านมาแล้วเห็น"
        )
          ? "ผ่านมาแล้วเห็น"
          : false;
        this.value6 = this.form.question_15_know_powerpod.includes("อื่นๆ")
          ? "อื่นๆ"
          : false;
      }
    },
  },
};
</script>

<style lang="scss">
.step-12 {
  .v-select.v-text-field input {
    // font-size: 24px;
  }
}
</style>
