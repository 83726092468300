<template>
  <div class="step-4">
    <v-layout justify-center>
      <v-flex shrink>
        <v-img
          src="../../assets/images/step4-home.png"
          height="120"
          contain
        ></v-img>
      </v-flex>
    </v-layout>

    <div class="step-title text-center my-6">พื้นที่อยู่อาศัย</div>

    <div style="padding-bottom: 8rem">
      <div>
        <label class="font-24 font-w-600">รหัสไปรษณีย์</label>

        <v-autocomplete
          v-model="form.postcode"
          :items="zipcodes"
          item-text="zipcode"
          item-value="zipcode"
          menu-props="offsetY"
          color="#000"
          outlined
          :rules="[(v) => !!v || 'กรุณาเลือกจังหวัด']"
          required
          @change="onChangeZipCode"
        >
        </v-autocomplete>
      </div>

      <v-layout align-center justify-space-between mx-n6>
        <v-flex>
          <v-divider style="border: 1px solid" />
        </v-flex>
        <v-flex shrink class="font-24 font-w-600 px-6">หรือ</v-flex>
        <v-flex>
          <v-divider style="border: 1px solid" />
        </v-flex>
      </v-layout>

      <div class="mt-4">
        <label class="font-24 font-w-600">จังหวัด</label>
        <v-autocomplete
          v-model="form.province_id"
          :items="provinces"
          item-text="PROVINCE_NAME"
          item-value="PROVINCE_ID"
          menu-props="offsetY"
          color="#000"
          outlined
          :rules="[(v) => !!v || 'กรุณาเลือกจังหวัด']"
          required
          @change="onChangeProvince"
        ></v-autocomplete>
      </div>

      <div class="mt-4">
        <label class="font-24 font-w-600">เขต/อำเภอ</label>
        <v-autocomplete
          v-model="form.district_id"
          :items="districts"
          item-text="AMPHUR_NAME"
          item-value="AMPHUR_ID"
          menu-props="offsetY"
          color="#000"
          outlined
          :rules="[(v) => !!v || 'กรุณาเลือกเขต/อำเภอ']"
          required
          @change="onChangeDistrict"
        ></v-autocomplete>
      </div>
    </div>

    <Loading color="#C71010" :active.sync="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import questionnaire from "../../services/api/questionnaire.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { mapState } from "vuex";
import "vue-scroll-picker/dist/style.css";

export default {
  components: { Loading },
  name: "QuestionnaireForm",

  computed: {
    ...mapState({
      step: (state) => state.questionnaire.step,
      form: (state) => state.questionnaire.form,
    }),
  },
  data() {
    return {
      provinces: [],
      districts: [],
      zipcodes: [],
      zip_code_id: null,
      isLoading: false,
    };
  },
  created() {
    this.getProvince();
  },
  mounted() {
    if (this.form.province_id) {
      let province = this.provinces.find(
        (item) => item.code === this.form.province_id
      );
      if (province) {
        this.districts = province.districts;
      }
    }
  },
  methods: {
    async getProvince() {
      this.isLoading = true;
      questionnaire
        .getMasterData()
        .then((res) => {
          this.provinces = res.data.data.provinces;
          this.zipcodes = res.data.data.zipcodes;
        })
        .then(() => {
          if (this.form.province_id) {
            let province = this.provinces.find(
              (item) => item.PROVINCE_ID === this.form.province_id
            );
            if (province) {
              this.districts = province.amphures;
            }
          }
          this.isLoading = false;
        });
    },

    onChangeZipCode() {
      this.form.province_id = null;
      this.form.province_name = null;
      this.form.district_id = null;
      this.form.district_name = null;
      if (this.form.postcode) {
        this.isLoading = true;

        questionnaire
          .getAmphureWithProvinceByZipcode(this.form.postcode)
          .then((res) => {
            this.form.province_id = res.data.data.PROVINCE_ID;
            this.form.province_name = res.data.data.PROVINCE_NAME;

            let province = this.provinces.find(
              (item) => item.PROVINCE_ID === this.form.province_id
            );

            if (province) {
              this.districts = province.amphures;
              this.form.district_id = res.data.data.AMPHUR_ID;
              this.form.district_name = res.data.data.AMPHUR_NAME;
            }

            this.isLoading = false;
          });
      }
    },
    onChangeProvince() {
      // Reset district
      this.form.district_id = null;
      this.form.district_name = null;
      this.form.postcode = null;

      let province = this.provinces.find(
        (item) => item.PROVINCE_ID === this.form.province_id
      );

      if (province) {
        this.form.province_name = province.PROVINCE_NAME;
        this.districts = province.amphures;
      }
    },
    onChangeDistrict() {
      let district = this.districts.find(
        (item) => item.AMPHUR_ID === this.form.district_id
      );

      if (district) {
        this.form.district_name = district.AMPHUR_NAME;
        this.form.postcode =
          district.districts.length > 0
            ? district.districts[0].zipcode.zipcode
            : null;
      }
    },
  },
};
</script>

<style lang="scss"></style>
