<template>
  <div class="home">
    <v-layout
      justify-space-between
      wrap
      align-center
      style="height: 100vh"
      class="px-6"
    >
      <v-flex xs12 class="d-flex justify-center">
        <v-img cover src="../assets/logo.png" width="384"></v-img>
      </v-flex>

      <v-flex xs12>
        <v-form
          ref="formLogin"
          v-model="valid"
          lazy-validation
          @submit="onSubmit"
        >
          <label for="password">รหัสผ่าน</label>
          <text-field
            type="password"
            placeholder="password"
            :outlined="true"
            :required="true"
            :textCenter="true"
            :borderActive="true"
            borderRadius="30px"
            :model="password"
            :rules="[
              (v) => !!v || 'กรุณากรอกรหัสผ่าน',
              (v) => v.length >= 6 || 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร',
            ]"
            @update:data="password = $event"
          ></text-field>
        </v-form>
      </v-flex>

      <v-flex xs12 class="d-flex justify-center">
        <v-btn
          style="border-radius: 35px"
          height="70"
          block
          color="#000"
          dark
          @click="onSubmit"
        >
          <span class="font-32 font-w-600">เริ่มทำแบบทดสอบ</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <Loading color="#C71010" :active.sync="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import TextField from "@/components/TextField.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { TextField, Loading },
  name: "HomeView",

  data: () => ({
    valid: true,
    isLoading: false,
    password: "",
  }),

  created() {
    // clear local storage
    localStorage.clear();
    // localStorage.removeItem("vuex");
  },

  methods: {
    validate() {
      this.$refs.formLogin.validate();
    },
    onSubmit(e) {
      e.preventDefault();
      if (this.$refs.formLogin.validate()) {
        this.checkLink();
      }
    },
    checkLink() {
      const token = this.$route.query.token;
      const password = this.password;
      this.isLoading = true;
      this.$store
        .dispatch("questionnaire/checkLink", { password, token })
        .then((res) => {
          this.isLoading = false;
          console.log("eeee", res);
          if (res.status === 200) {
            // check link status
            if (res.data && res.data.status === 1) {
              // go to questionnaire detail
              this.$router.push({
                name: "QuestionnaireDetail",
                params: { id: res.data.id },
              });
            } else {
              this.$router.push("/questionnaire");
            }
          } else {
            this.$swal.fire({
              position: "center",
              icon: "error",
              title: "รหัสผ่านไม่ถูกต้อง",
              showConfirmButton: true,
              confirmButtonColor: "var(--main-color)",
            });
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
.home {
  background-color: #f3f3f3;
  height: 100vh;
}
</style>
