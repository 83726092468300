<template>
  <div
    class="main-step"
    :class="step === 16 || step === 17 ? 'summary-step' : ''"
  >
    <div class="header-formm" v-if="step < 16">
      <v-app-bar app color="#fff" flat>
        <v-layout justify-center align-center style="width: 100%">
          <v-flex shrink>
            <v-img cover src="../assets/logo.png" width="112"></v-img>
          </v-flex>
          <v-flex>
            <v-layout align-center>
              <v-flex class="pr-1">
                <v-progress-linear
                  :value="progress"
                  background-color="#F3F3F3"
                  color="#55C5C8"
                ></v-progress-linear>
              </v-flex>
              <v-flex shrink>
                <span class="font-16 font-w-600" style="color: #a3a3a3">
                  {{ step }}/15
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-app-bar>
    </div>

    <div class="px-6">
      <div class="form-step">
        <v-form ref="form" v-model="valid" lazy-validation>
          <div v-if="step === 1">
            <Step1 />
          </div>

          <div v-if="step === 2">
            <Step2 />
          </div>

          <div v-if="step === 3">
            <Step3 />
          </div>

          <div v-if="step === 4">
            <Step4 />
          </div>

          <div v-if="step === 5">
            <Step5 :isValid="isValidStep5" @onValid="isValidStep5 = $event" />
          </div>

          <div v-if="step === 6">
            <Step6 :isValid="isValidStep6" @onValid="isValidStep6 = $event" />
          </div>

          <div v-if="step === 7">
            <Step7 :stepProblem="step7_no" />
          </div>

          <div v-if="step === 8 || step === 9 || step === 10 || step === 11">
            <StepGroup8_11 />
          </div>

          <div v-if="step === 12">
            <Step12 />
          </div>

          <div v-if="step === 13">
            <Step13 />
          </div>

          <div v-if="step === 14">
            <Step14 />
          </div>

          <div v-if="step === 15">
            <Step15
              :isValid="isValidStep15"
              @onValid="isValidStep15 = $event"
            />
          </div>
        </v-form>
      </div>
    </div>

    <div v-if="step === 16">
      <Step17Policy />
    </div>

    <div v-if="step === 17">
      <step-16-summary />
    </div>

    <!-- Button  Step Form -->
    <v-layout class="btn-step" v-if="step != 17">
      <v-flex shrink>
        <v-btn
          v-if="step > 1"
          style="border-radius: 30px"
          height="70"
          width="125"
          color="#F3F3F3"
          dark
          depressed
          @click="backStep(stepBefore)"
        >
          <span class="font-26 font-w-600" style="color: #a3a3a3">
            ย้อนกลับ
          </span>
        </v-btn>
      </v-flex>
      <v-spacer />
      <v-flex shrink>
        <v-btn
          v-if="step > 0 && step < 16"
          style="border-radius: 30px"
          height="70"
          width="172"
          color="#55C5C8"
          :dark="step !== 17"
          depressed
          :disabled="step === 17"
          @click="setStep(step + 1)"
        >
          <span v-if="step == 15" class="font-32 font-w-600">ส่ง</span>
          <span v-else-if="step == 16" class="font-32 font-w-600">ยืนยัน</span>
          <span v-else class="font-32 font-w-600">ถัดไป</span>

          <Icon
            v-if="step < 15"
            style="font-size: 3rem"
            icon="material-symbols:navigate-next"
          />
        </v-btn>
      </v-flex>
    </v-layout>

    <!-- Button Submit Form -->
    <v-layout class="btn-step" v-if="step == 16">
      <v-flex shrink>
        <v-btn
          style="border-radius: 30px"
          height="70"
          width="125"
          color="#F3F3F3"
          dark
          depressed
          @click="onAccept('ไม่ยอมรับ')"
        >
          <span class="font-26 font-w-600" style="color: #a3a3a3">
            ไม่ยอมรับ
          </span>
        </v-btn>
      </v-flex>
      <v-spacer />
      <v-flex shrink>
        <v-btn
          style="border-radius: 30px"
          height="70"
          width="172"
          color="#55C5C8"
          dark
          depressed
          @click="onAccept('ยอมรับ')"
        >
          <span class="font-32 font-w-600">ยอมรับ</span>
        </v-btn>
      </v-flex>
    </v-layout>

    <!-- Modal -->
    <v-dialog width="340" persistent v-model="dialog">
      <v-card flat color="transparent" class="py-3">
        <box-card class="py-5 px-3">
          <v-layout justify-center mt-6>
            <v-flex shrink>
              <v-img
                src="../assets/images/Note.png"
                width="98px"
                aspect-ratio="1"
              ></v-img>
            </v-flex>
          </v-layout>

          <div class="font-40 font-w-600 text-center">{{ titleDialog }}</div>
          <div class="font-20 font-w-500 text-center" v-html="textDialog"></div>

          <v-row class="mt-6">
            <v-col cols="6">
              <v-btn
                block
                depressed
                color="#F3F3F3"
                style="border-radius: 35px"
                @click="dialog = false"
              >
                <span style="color: #a3a3a3" class="font-24 font-w-600">
                  ยกเลิก
                </span>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                block
                depressed
                color="#55C5C8"
                dark
                style="border-radius: 35px"
                @click="submit()"
              >
                <span class="font-24 font-w-600"> ยืนยัน </span>
              </v-btn>
            </v-col>
          </v-row>
        </box-card>
      </v-card>
    </v-dialog>

    <Loading color="#C71010" :active.sync="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import Step1 from "@/components/questionnaire/Step1.vue";
import Step2 from "@/components/questionnaire/Step2.vue";
import Step3 from "@/components/questionnaire/Step3.vue";
import Step4 from "@/components/questionnaire/Step4.vue";
import Step5 from "@/components/questionnaire/Step5.vue";
import Step6 from "@/components/questionnaire/Step6.vue";
import Step7 from "@/components/questionnaire/Step7.vue";
import StepGroup8_11 from "@/components/questionnaire/StepGroup8_11.vue";
import Step12 from "@/components/questionnaire/Step12.vue";
import Step13 from "@/components/questionnaire/Step13.vue";
import Step14 from "@/components/questionnaire/Step14.vue";
import Step15 from "@/components/questionnaire/Step15.vue";
import Step16Summary from "@/components/questionnaire/Step16Summary.vue";
import Step17Policy from "@/components/questionnaire/Step17Policy.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState } from "vuex";
import BoxCard from "@/components/BoxCard.vue";
export default {
  components: {
    BoxCard,
    Icon,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    StepGroup8_11,
    Step12,
    Step13,
    Step14,
    Step15,
    Step16Summary,
    Step17Policy,
    Loading,
  },
  name: "QuestionnaireForm",
  computed: {
    ...mapState({
      questionnaire: (state) => state.questionnaire,
      step: (state) => state.questionnaire.step,
      stepBefore: (state) => state.questionnaire.stepBefore,
      form: (state) => state.questionnaire.form,
    }),

    progress() {
      return this.step * 6.666666666666667;
    },
  },
  data() {
    return {
      valid: true,
      isLoading: false,
      isValidStep5: true,
      isValidStep6: true,
      isValidStep15: true,
      step7_no: 1,
      dialog: false,
      titleDialog: "ยอมรับ",
      textDialog: "",
      acceptText: "เมื่อส่งแบบสดทอบแล้ว <br />คุณจะไม่สามารถแก้ไข/ทำซ้ำได้",
      rejectText: "คุณไม่สามารถส่งแบบสอบถามนี้ได้ <br />ยืนยัน เพื่อลบข้อมูล",
    };
  },
  created() {
    if (!this.questionnaire.token) {
      this.$router.go(-1);
    }
  },
  methods: {
    setStep(step) {
      let currentStep = step - 1;
      console.log("currentStep", currentStep);
      switch (currentStep) {
        // validate step 5
        case 5:
          if (this.form.question_5_select_name.length == 0) {
            this.isValidStep5 = false;
          } else {
            this.isValidStep5 = true;
            if (this.$refs.form.validate()) {
              // เช็คเงื่อนไขของ step 5 หากในหลายข้อที่เลือก ไม่มีข้อ “มีปัญหา” ให้ข้ามไป step 12 แต่ถ้ามีให้ไป step 6
              let isSkip = true;
              this.form.question_5_select_name.forEach((item) => {
                if (item == 'เพราะมี "ปัญหาร่างกาย/ความกังวล"') {
                  isSkip = false;
                }
              });

              if (isSkip) {
                this.$store.commit("questionnaire/setStep", 12);
                this.$store.commit("questionnaire/setStepBefore", step - 1);
              } else {
                this.$store.commit("questionnaire/setStep", step);
                this.$store.commit("questionnaire/setStepBefore", step - 1);
              }
            }
          }
          break;

        case 6:
          if (this.form.body_problem_concern.length == 0) {
            this.isValidStep6 = false;
          } else {
            this.isValidStep6 = true;
            if (this.$refs.form.validate()) {
              // เช็คเงื่อนไขของ step 6 หากในหลายข้อที่เลือกมี "ความเจ็บปวด, ตัวเย็นขี้หนาว, ร้อนวูบวาบ, บวม" ให้ไป step 7 แต่ถ้าไม่มีให้ไป step 8
              let isSkip = true;
              this.form.body_problem_concern.forEach((item) => {
                if (
                  item.question_6_problem_name == "ความเจ็บปวด" ||
                  item.question_6_problem_name == "ตัวเย็นขี้หนาว" ||
                  item.question_6_problem_name == "ร้อนวูบวาบ" ||
                  item.question_6_problem_name == "บวม"
                ) {
                  isSkip = false;
                }
              });

              if (isSkip) {
                this.$store.commit("questionnaire/setStep", 8);
                this.$store.commit("questionnaire/setStepBefore", step - 1);
              } else {
                this.$store.commit("questionnaire/setStep", step);
                this.$store.commit("questionnaire/setStepBefore", step - 1);
              }
            }
          }
          break;

        case 7:
          // check step no ใน form step7 ถ้ายังไม่ถึงข้อสุดท้าย ให้อยู่ใน form step 7 ทำต่อให้ครบ ถ้าครบแล้ว ให้ไป step 8
          console.log(this.step7_no);
          if (this.step7_no === this.form.body_problem_concern.length) {
            console.log("ไป step 8");
            // this.$store.commit("questionnaire/setStep", 8);
            // this.$store.commit("questionnaire/setStepBefore", step - 1);
          } else {
            this.step7_no++;
          }
          break;

        case 15:
          if (this.form.question_15_know_powerpod.length == 0) {
            this.isValidStep15 = false;
          } else {
            this.isValidStep15 = true;
            if (this.$refs.form.validate()) {
              this.$store.commit("questionnaire/setStep", step);
              this.$store.commit("questionnaire/setStepBefore", step - 1);
            }
          }
          break;
        default:
          // validate form
          if (this.$refs.form.validate()) {
            this.$store.commit("questionnaire/setStep", step);
            this.$store.commit("questionnaire/setStepBefore", step - 1);
          }
      }
    },
    backStep() {
      // เช็คว่า ถ้าเป็น step 12  ให้กลับไป step 8
      console.log("stepBefore", this.step);
      if (this.step === 12) {
        // check ว่า step 5 มีการเลือกข้อ เพราะมี "ปัญหาร่างกาย/ความกังวล" ไหม
        // ถ้ามีให้กลับไป step 8 แต่ถ้าไม่มีให้กลับไป step 5
        let isSkip = false;
        this.form.question_5_select_name.forEach((item) => {
          if (item == 'เพราะมี "ปัญหาร่างกาย/ความกังวล"') {
            isSkip = true;
          }
        });

        if (isSkip) {
          this.$store.commit("questionnaire/setStep", 8);
          this.$store.commit("questionnaire/setStepBefore", 7);
        } else {
          this.$store.commit("questionnaire/setStep", 5);
          this.$store.commit("questionnaire/setStepBefore", 4);
        }
      } else {
        this.$store.commit("questionnaire/setStep", this.stepBefore);
        this.$store.commit("questionnaire/setStepBefore", this.stepBefore - 1);
      }
    },
    onAccept(type) {
      this.titleDialog = type;
      if (type == "ยอมรับ") {
        this.textDialog = this.acceptText;
      } else {
        this.textDialog = this.rejectText;
      }

      this.dialog = true;
    },
    confirmReject() {
      console.log("reject");
      //clear data
      this.$store.commit("questionnaire/clearData");
      // redirect to home
      this.$router.go(-1);
    },
    confirmAccept() {
      console.log("accept");
      this.dialog = false;

      this.isLoading = true;
      // save data
      this.$store
        .dispatch("questionnaire/saveQuestionnaire", this.form)
        .then((res) => {
          console.log(res);
          this.isLoading = false;
          this.setStep(17);

          // this.$swal
          //   .fire({
          //     icon: "success",
          //     title: "ส่งแบบสอบถามเรียบร้อย",
          //     showConfirmButton: true,
          //     confirmButtonColor: "var(--main-color)",
          //   })
          //   .then((result) => {
          //     if (result.isConfirmed) {
          //       this.$store.commit("questionnaire/clearData");
          //       // redirect to home
          //       this.$router.go(-1);
          //     }
          //   });
        })
        .catch((err) => {
          console.log(err);
          this.$swal.fire({
            icon: "success",
            title: "บันทึกข้อมูลไม่สำเร็จ",
            showConfirmButton: true,
            confirmButtonColor: "var(--main-color)",
          });
          this.isLoading = false;
        });
    },
    submit() {
      if (this.titleDialog === "ไม่ยอมรับ") {
        this.confirmReject();
      } else {
        this.confirmAccept();
      }
    },
  },
};
</script>

<style lang="scss">
.summary-step {
  min-height: 100vh !important;
}
.main-step {
  min-height: calc(100vh - 58px);
  position: relative;

  .header-form {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  .step-title {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: #000000;
  }

  .btn-step {
    // box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    width: 100%;
    bottom: 15px;
    padding: 0px 1.5rem;
    // background-color: rgb(0, 255, 94);
    // padding: 15px 1.5rem 15px 1.5rem;
  }
}
.v-dialog {
  box-shadow: unset !important;
}
</style>
