<template>
  <div class="step-3">
    <div class="step-title text-center my-6">
      คุณพอจะทราบสาเหตุ <br />
      ที่ทำให้เกิดอาการ/ <br />
      ปัญหาออกหรือไม่?
    </div>

    <div style="padding-bottom: 8rem">
      <div class="mt-12 d-flex justify-center">
        <v-btn
          style="border-radius: 30px"
          :color="
            form.body_problem_concern_questionnaire[indexAt]
              .question_8_is_know == 'ไม่ทราบ'
              ? '#000000'
              : '#F3F3F3'
          "
          :dark="
            form.body_problem_concern_questionnaire[indexAt]
              .question_8_is_know == 'ไม่ทราบ'
          "
          depressed
          block
          height="86"
          @click="setStep8('question_8_is_know', 'ไม่ทราบ')"
        >
          <v-icon class="font-32 mr-4">mdi-close</v-icon>
          <span class="font-32 font-w-600">ไม่ทราบ</span>
        </v-btn>
      </div>

      <div class="mt-6 d-flex justify-center">
        <v-btn
          style="border-radius: 30px"
          :color="
            form.body_problem_concern_questionnaire[indexAt]
              .question_8_is_know == 'ทราบ'
              ? ''
              : '#F3F3F3'
          "
          :dark="
            form.body_problem_concern_questionnaire[indexAt]
              .question_8_is_know == 'ทราบ'
          "
          depressed
          block
          height="86"
          @click="setStep8('question_8_is_know', 'ทราบ')"
        >
          <v-icon class="font-32 mr-4">mdi-check</v-icon>
          <span class="font-32 font-w-600"> ทราบ</span>
        </v-btn>
      </div>

      <div
        v-if="
          form.body_problem_concern_questionnaire[indexAt].question_8_is_know ==
          'ทราบ'
        "
        class="mt-6"
      >
        <label class="font-24 font-w-600">สาเหตุ</label>
        <v-textarea
          class="mt-2"
          v-model="
            form.body_problem_concern_questionnaire[indexAt]
              .question_8_cause_question
          "
          :rules="[
            (v) => !!v || 'กรุณากรอกข้อมูล',
            (v) =>
              (v && v.length <= 150) || 'กรุณากรอกข้อมูลไม่เกิน 150 ตัวอักษร',
          ]"
          style="border-radius: 30px"
          rows="3"
          color="#000"
          background-color="#fff"
          placeholder="โปรดระบุ"
          outlined
          @input="setStep8('question_8_cause_question', $event)"
        ></v-textarea>
      </div>
    </div>
  </div>
</template>

<script>
// import { Icon } from "@iconify/vue2";
import { mapState } from "vuex";
import "vue-scroll-picker/dist/style.css";

export default {
  // components: { Icon },
  name: "QuestionnaireForm",

  props: {
    indexAt: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapState({
      step: (state) => state.questionnaire.step,
      form: (state) => state.questionnaire.form,
    }),
  },

  methods: {
    setStep8(key, value) {
      this.$store.commit("questionnaire/setValueStep8", {
        key: key,
        index: this.indexAt,
        val: value,
      });
    },
  },
};
</script>

<style lang="scss"></style>
