import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import questionnaireForm from "../views/QuestionnaireForm.vue";
import QuestionnaireDetail from "../views/QuestionnaireDetail.vue";
import PageNotFound from "../views/PageNotFound.vue";
import questionnaire from "../services/api/questionnaire.service";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/questionnaire",
    name: "questionnaire",
    component: questionnaireForm,
  },
  {
    path: "/questionnaire-detail",
    name: "QuestionnaireDetail",
    component: QuestionnaireDetail,
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  localStorage.removeItem("vuex");
  // console.log("to", to);
  // console.log("from", from);
  // console.log("next", next);

  // get token from url
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  // ถ้าไม่มี token ให้ไปหน้า 404
  if (to.name === "home" && !token) {
    // remove token from local storage
    localStorage.removeItem("vuex");
    next({ name: "PageNotFound" });
  }
  // ถ้ามี token ให้เช็คว่ามีใน db ไหม
  else if (to.name === "home" && token) {
    // remove token from local storage
    localStorage.removeItem("vuex");

    questionnaire.checkToken(token).then((res) => {
      if (res.data.status === 200) {
        next();
      } else {
        // remove token from local storage
        localStorage.removeItem("vuex");
        next({ name: "PageNotFound" });
      }
    });
  } else if (to.name === "QuestionnaireDetail") {
    // check status ก่อนว่า link นี้ได้ทำแบบสอบถามไปแล้วหรือยัง
    if (
      store.state.questionnaire.questionnaireDetail &&
      store.state.questionnaire.questionnaireDetail.status == 1
    ) {
      next();
    } else {
      router.go(-1);
    }
  } else next();
});

export default router;
