var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-9"},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-img',{attrs:{"src":require("../../assets/images/step3-gender.png"),"height":"120","contain":""}})],1)],1),_vm._m(0),_c('div',{staticStyle:{"padding-bottom":"8rem"}},[_c('box-card',{staticClass:"py-3 custom_checkbox_radio"},[_c('v-radio-group',{attrs:{"rules":[(v) => !!v || 'กรุณาเลือกอาการ/ปัญหา']},on:{"change":_vm.onChangeRadio},model:{value:(
          _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
            .question_9_select_name
        ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
            , "question_9_select_name", $$v)},expression:"\n          form.body_problem_concern_questionnaire[indexAt]\n            .question_9_select_name\n        "}},[_c('v-radio',{staticClass:"mb-6 px-3 mt-0",attrs:{"color":"#C71010","value":"ไม่รู้"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-24",staticStyle:{"color":"#000000"}},[_vm._v(" ไม่รู้ ")])]},proxy:true}])}),_c('v-divider'),_c('v-radio',{staticClass:"mt-6 mb-6 px-3",attrs:{"color":"#C71010","value":"มากกว่าหนึ่งปีที่ผ่านมา"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-24",staticStyle:{"color":"#000000"}},[_vm._v(" มากกว่าหนึ่งปีที่ผ่านมา ")])]},proxy:true}])}),_c('v-divider'),_c('v-radio',{staticClass:"mt-3 mb-3 px-3",attrs:{"color":"#C71010","value":"เดือนที่แล้ว"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-autocomplete',{attrs:{"items":_vm.months,"disabled":_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                      .question_9_select_name != 'เดือนที่แล้ว',"hide-details":"","menu-props":"offsetY","color":"#000","outlined":"","rules":_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                      .question_9_select_name == 'เดือนที่แล้ว'
                      ? [(v) => !!v || 'กรุณาเลือกจำนวนเดือน']
                      : [],"required":""},model:{value:(
                    _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                      .question_9_last_month
                  ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                      , "question_9_last_month", $$v)},expression:"\n                    form.body_problem_concern_questionnaire[indexAt]\n                      .question_9_last_month\n                  "}})],1),_c('v-flex',{staticClass:"pl-2 font-24",style:(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                    .question_9_select_name != 'เดือนที่แล้ว'
                    ? { color: '#A3A3A3' }
                    : { color: '#000' }),attrs:{"xs6":""}},[_vm._v(" เดือนที่แล้ว ")])],1)]},proxy:true}])}),_c('v-divider'),_c('v-radio',{staticClass:"mt-3 mb-3 px-3",attrs:{"color":"#C71010","value":"สัปดาห์ที่ผ่านมา"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-autocomplete',{attrs:{"items":_vm.weeks,"disabled":_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                      .question_9_select_name != 'สัปดาห์ที่ผ่านมา',"hide-details":"","menu-props":"offsetY","color":"#000","outlined":"","rules":_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                      .question_9_select_name == 'สัปดาห์ที่ผ่านมา'
                      ? [(v) => !!v || 'กรุณาเลือกจำนวนเดือน']
                      : [],"required":""},model:{value:(
                    _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                      .question_9_last_week
                  ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                      , "question_9_last_week", $$v)},expression:"\n                    form.body_problem_concern_questionnaire[indexAt]\n                      .question_9_last_week\n                  "}})],1),_c('v-flex',{staticClass:"pl-2 font-24",style:(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                    .question_9_select_name != 'สัปดาห์ที่ผ่านมา'
                    ? { color: '#A3A3A3' }
                    : { color: '#000' }),attrs:{"xs6":""}},[_vm._v(" สัปดาห์ที่ผ่านมา ")])],1)]},proxy:true}])})],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-title text-center my-6"},[_vm._v(" คุณเริ่มรู้สึกถึงอาการ "),_c('br'),_vm._v(" /ปัญหา ตั้งแต่เมื่อใด? ")])
}]

export { render, staticRenderFns }