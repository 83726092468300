<template>
  <div class="step-2">
    <v-layout justify-center>
      <v-flex shrink>
        <v-img
          src="../../assets/images/step2-birthdate.png"
          height="120"
          contain
        ></v-img>
      </v-flex>
    </v-layout>

    <div class="step-title text-center my-6">วันเกิด</div>

    <v-row class="custom-select-scroll">
      <v-col cols="4">
        <scroll-picker
          :drag-sensitivity="dragSensitivity"
          :touch-sensitivity="touchSensitivity"
          :scroll-sensitivity="scrollSensitivity"
          :options="daysInMonth"
          v-model="date"
        />
      </v-col>
      <v-col cols="4">
        <scroll-picker
          :drag-sensitivity="dragSensitivity"
          :touch-sensitivity="touchSensitivity"
          :scroll-sensitivity="scrollSensitivity"
          :options="months"
          v-model="month"
        />
      </v-col>
      <v-col cols="4">
        <scroll-picker
          :drag-sensitivity="dragSensitivity"
          :touch-sensitivity="touchSensitivity"
          :scroll-sensitivity="scrollSensitivity"
          :options="years"
          v-model="year"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ScrollPicker } from "vue-scroll-picker";
import "vue-scroll-picker/dist/style.css";

export default {
  components: {
    ScrollPicker,
  },
  name: "QuestionnaireForm",

  computed: {
    ...mapState({
      step: (state) => state.questionnaire.step,
      form: (state) => state.questionnaire.form,
    }),
  },
  data() {
    return {
      dragSensitivity: 1.7,
      touchSensitivity: 1.7,
      scrollSensitivity: 1,
      options: [
        { value: "instagram", name: "Instagram" },
        { value: "facebook", name: "Facebook" },
        { value: "line", name: "Line" },
      ],

      date: "",
      month: "",
      year: "",
      daysInMonth: [],
      months: [
        { name: "มกราคม", value: "1" },
        { name: "กุมภาพันธ์", value: "2" },
        { name: "มีนาคม", value: "3" },
        { name: "เมษายน", value: "4" },
        { name: "พฤษภาคม", value: "5" },
        { name: "มิถุนายน", value: "6" },
        { name: "กรกฎาคม", value: "7" },
        { name: "สิงหาคม", value: "8" },
        { name: "กันยายน", value: "9" },
        { name: "ตุลาคม", value: "10" },
        { name: "พฤศจิกายน", value: "11" },
        { name: "ธันวาคม", value: "12" },
      ],
      years: [],
    };
  },
  watch: {
    date() {
      this.form.birthdate = `${this.year}-${
        this.month < 10 ? "0" + this.month : this.month
      }-${this.date < 10 ? "0" + this.date : this.date}`;
    },
    month() {
      this.form.birthdate = `${this.year}-${
        this.month < 10 ? "0" + this.month : this.month
      }-${this.date < 10 ? "0" + this.date : this.date}`;
    },
    year() {
      this.form.birthdate = `${this.year}-${
        this.month < 10 ? "0" + this.month : this.month
      }-${this.date < 10 ? "0" + this.date : this.date}`;
    },
  },
  created() {
    this.generateDateOptions();

    let birthdate = this.form.birthdate;
    if (birthdate) {
      let date = new Date(birthdate);
      this.date = date.getDate().toString();
      this.month = (date.getMonth() + 1).toString();
      this.year = date.getFullYear().toString();
    }
  },
  methods: {
    formatTel(tel) {
      return tel.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    },
    generateDateOptions() {
      let thisYear = new Date().getFullYear();
      let availableDays = 31;

      // days
      for (let i = 1; i <= availableDays; i++) {
        this.daysInMonth.push(i.toString());
      }

      // years
      let beYear = thisYear + 543;
      let yearReverse = 100;
      for (let i = beYear; i >= beYear - yearReverse; i--) {
        this.years.push({
          name: i.toString(),
          value: (i - 543).toString(),
        });
      }
    },
    updateData(key, value) {
      this[key] = value;

      let date = this.date;
      date = date < 10 ? `0${date}` : date;
      let month = this.month;
      month = month < 10 ? `0${month}` : month;
      let year = this.year;
      this.form.birthdate = `${year}-${month}-${date}`;
    },
  },
};
</script>

<style lang="scss"></style>
