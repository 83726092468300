<template>
  <div class="custom-text-field">
    <v-text-field
      :outlined="outlined"
      :style="{ 'border-radius': borderRadius }"
      :placeholder="placeholder"
      :color="color"
      :background-color="backgroundColor"
      :value="model"
      :required="required"
      :rules="rules"
      :hide-details="hideDetails"
      :readonly="readonly"
      :type="type"
      :class="{ 'text-center': textCenter, 'border-active': borderActive }"
      @input="updateValue"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    model: null,
    placeholder: String,
    type: {
      type: String,
      default: "text",
    },
    color: {
      type: String,
      default: "#000",
    },
    backgroundColor: {
      type: String,
      default: "#fff",
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: "10px",
    },
    textCenter: {
      type: Boolean,
      default: false,
    },
    borderActive: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    updateValue(value) {
      this.$emit("update:data", value);
    },
  },
};
</script>

<style lang="scss">
.custom-text-field {
  .border-active.v-text-field--outlined fieldset {
    border: 2px solid #000 !important;
  }
  .text-center input {
    text-align: center;
  }

  // Hide number input arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
}
</style>
