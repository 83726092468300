var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-3"},[_vm._m(0),_c('div',{staticStyle:{"padding-bottom":"8rem"}},[_c('div',{staticClass:"mt-12 d-flex justify-center"},[_c('v-btn',{staticStyle:{"border-radius":"30px"},attrs:{"color":_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
            .question_8_is_know == 'ไม่ทราบ'
            ? '#000000'
            : '#F3F3F3',"dark":_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
            .question_8_is_know == 'ไม่ทราบ',"depressed":"","block":"","height":"86"},on:{"click":function($event){return _vm.setStep8('question_8_is_know', 'ไม่ทราบ')}}},[_c('v-icon',{staticClass:"font-32 mr-4"},[_vm._v("mdi-close")]),_c('span',{staticClass:"font-32 font-w-600"},[_vm._v("ไม่ทราบ")])],1)],1),_c('div',{staticClass:"mt-6 d-flex justify-center"},[_c('v-btn',{staticStyle:{"border-radius":"30px"},attrs:{"color":_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
            .question_8_is_know == 'ทราบ'
            ? ''
            : '#F3F3F3',"dark":_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
            .question_8_is_know == 'ทราบ',"depressed":"","block":"","height":"86"},on:{"click":function($event){return _vm.setStep8('question_8_is_know', 'ทราบ')}}},[_c('v-icon',{staticClass:"font-32 mr-4"},[_vm._v("mdi-check")]),_c('span',{staticClass:"font-32 font-w-600"},[_vm._v(" ทราบ")])],1)],1),(
        _vm.form.body_problem_concern_questionnaire[_vm.indexAt].question_8_is_know ==
        'ทราบ'
      )?_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"font-24 font-w-600"},[_vm._v("สาเหตุ")]),_c('v-textarea',{staticClass:"mt-2",staticStyle:{"border-radius":"30px"},attrs:{"rules":[
          (v) => !!v || 'กรุณากรอกข้อมูล',
          (v) =>
            (v && v.length <= 150) || 'กรุณากรอกข้อมูลไม่เกิน 150 ตัวอักษร',
        ],"rows":"3","color":"#000","background-color":"#fff","placeholder":"โปรดระบุ","outlined":""},on:{"input":function($event){return _vm.setStep8('question_8_cause_question', $event)}},model:{value:(
          _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
            .question_8_cause_question
        ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
            , "question_8_cause_question", $$v)},expression:"\n          form.body_problem_concern_questionnaire[indexAt]\n            .question_8_cause_question\n        "}})],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-title text-center my-6"},[_vm._v(" คุณพอจะทราบสาเหตุ "),_c('br'),_vm._v(" ที่ทำให้เกิดอาการ/ "),_c('br'),_vm._v(" ปัญหาออกหรือไม่? ")])
}]

export { render, staticRenderFns }