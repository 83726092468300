<template>
  <div class="step-10">
    <div class="step-title text-center mt-6">
      ที่ผ่านมา <br />
      คุณทำอะไรไปบ้าง <br />
      เพื่อแก้ไขอาการ/ปัญหา
    </div>

    <div style="color: #a3a3a3" class="font-20 text-center mb-4">
      (*เลือกตอบได้หลายข้อ)
    </div>

    <div style="padding-bottom: 8rem">
      <box-card class="custom_checkbox_radio">
        <!-- ตรวจที่โรงพยาบาล/คลินิก -->
        <div class="px-4 pt-4">
          <div class="font-24 font-w-600">ตรวจที่โรงพยาบาล/คลินิก</div>
          <v-layout align-end justify-space-between>
            <v-flex shrink>
              <v-radio-group
                class="mt-0"
                v-model="
                  form.body_problem_concern_questionnaire[indexAt]
                    .question_11_hospital
                "
                :rules="[(v) => !!v || 'กรุณาเลือกตรวจที่โรงพยาบาล/คลินิก']"
              >
                <v-radio
                  v-for="(item, i) in radioList"
                  :key="i"
                  color="#C71010"
                  :value="item"
                  class="pb-4"
                >
                  <template v-slot:label>
                    <span class="font-20" style="color: #000000">
                      {{ item }}
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex shrink pb-2>
              <v-img
                width="80"
                aspect-ratio="1"
                src="../../assets/images/step11-1.png"
              ></v-img>
            </v-flex>
          </v-layout>
        </div>

        <v-divider></v-divider>

        <!-- นวด -->
        <div class="px-4 pt-4">
          <div class="font-24 font-w-600">นวด</div>
          <v-layout align-end justify-space-between>
            <v-flex shrink>
              <v-radio-group
                class="mt-0"
                v-model="
                  form.body_problem_concern_questionnaire[indexAt]
                    .question_11_massage
                "
                :rules="[(v) => !!v || 'กรุณาเลือกนวด']"
              >
                <v-radio
                  v-for="(item, i) in radioList"
                  :key="i"
                  color="#C71010"
                  :value="item"
                  class="pb-4"
                >
                  <template v-slot:label>
                    <span class="font-20" style="color: #000000">
                      {{ item }}
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex shrink pb-2>
              <v-img
                width="80"
                aspect-ratio="1"
                src="../../assets/images/step11-2.png"
              ></v-img>
            </v-flex>
          </v-layout>
        </div>

        <v-divider></v-divider>

        <!-- เข้าคลาสโยคะ/พิลาทิส -->
        <div class="px-4 pt-4">
          <div class="font-24 font-w-600">เข้าคลาสโยคะ/พิลาทิส</div>
          <v-layout align-end justify-space-between>
            <v-flex shrink>
              <v-radio-group
                class="mt-0"
                v-model="
                  form.body_problem_concern_questionnaire[indexAt]
                    .question_11_yoga
                "
                :rules="[(v) => !!v || 'กรุณาเลือกเข้าคลาสโยคะ/พิลาทิส']"
                required
              >
                <v-radio
                  v-for="(item, i) in radioList"
                  :key="i"
                  color="#C71010"
                  :value="item"
                  class="pb-4"
                >
                  <template v-slot:label>
                    <span class="font-20" style="color: #000000">
                      {{ item }}
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex shrink pb-2>
              <v-img
                width="80"
                aspect-ratio="1"
                src="../../assets/images/step11-3.png"
              ></v-img>
            </v-flex>
          </v-layout>
        </div>

        <v-divider></v-divider>

        <!-- ไปฟิตเนส -->
        <div class="px-4 pt-4">
          <div class="font-24 font-w-600">ไปฟิตเนส</div>
          <v-layout align-end justify-space-between>
            <v-flex shrink>
              <v-radio-group
                class="mt-0"
                v-model="
                  form.body_problem_concern_questionnaire[indexAt]
                    .question_11_fitness
                "
                :rules="[(v) => !!v || 'กรุณาเลือกไปฟิตเนส']"
              >
                <v-radio
                  v-for="(item, i) in radioList"
                  :key="i"
                  color="#C71010"
                  :value="item"
                  class="pb-4"
                >
                  <template v-slot:label>
                    <span class="font-20" style="color: #000000">
                      {{ item }}
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex shrink pb-2>
              <v-img
                width="80"
                aspect-ratio="1"
                src="../../assets/images/step11-4.png"
              ></v-img>
            </v-flex>
          </v-layout>
        </div>

        <v-divider></v-divider>

        <!-- ออกกำลังกายด้วยตัวเอง -->
        <div class="px-4 pt-4">
          <div class="font-24 font-w-600">ออกกำลังกายด้วยตัวเอง</div>
          <v-layout align-end justify-space-between>
            <v-flex shrink>
              <v-radio-group
                class="mt-0"
                v-model="
                  form.body_problem_concern_questionnaire[indexAt]
                    .question_11_exercise
                "
                :rules="[(v) => !!v || 'กรุณาเลือกออกกำลังกายด้วยตัวเอง']"
              >
                <v-radio
                  v-for="(item, i) in radioList"
                  :key="i"
                  color="#C71010"
                  :value="item"
                  class="pb-4"
                >
                  <template v-slot:label>
                    <span class="font-20" style="color: #000000">
                      {{ item }}
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex shrink pb-2>
              <v-img
                width="80"
                aspect-ratio="1"
                src="../../assets/images/step11-5.png"
              ></v-img>
            </v-flex>
          </v-layout>
        </div>

        <v-divider></v-divider>

        <!-- อื่นๆ -->
        <div class="px-4 pt-4">
          <div class="font-24 font-w-600">อื่นๆ</div>
          <v-textarea
            class="mt-2"
            v-model="
              form.body_problem_concern_questionnaire[indexAt]
                .question_11_exercise_other
            "
            :rules="
              form.body_problem_concern_questionnaire[indexAt]
                .question_11_exercise_other
                ? [
                    (v) =>
                      (v && v.length <= 150) ||
                      'กรุณากรอกข้อมูลไม่เกิน 150 ตัวอักษร',
                  ]
                : []
            "
            rows="3"
            color="#000"
            background-color="#fff"
            placeholder="โปรดระบุ"
            outlined
          ></v-textarea>
        </div>
      </box-card>
    </div>
  </div>
</template>

<script>
// import { Icon } from "@iconify/vue2";
import { mapState } from "vuex";
import "vue-scroll-picker/dist/style.css";
import BoxCard from "../BoxCard.vue";

export default {
  components: { BoxCard },
  // components: { Icon },
  name: "QuestionnaireForm",

  props: {
    indexAt: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      weeks: [1, 2, 3, 4],

      radioList: ["ทำอยู่", "เลิกแล้ว", "ไม่เคย"],
    };
  },

  computed: {
    ...mapState({
      step: (state) => state.questionnaire.step,
      form: (state) => state.questionnaire.form,
    }),
  },

  created() {
    window.scrollTo(0, 0);
  },

  methods: {},
};
</script>

<style lang="scss">
.step-10 {
  .v-select.v-text-field input {
    // font-size: 24px;
  }
}
</style>
