<template>
  <div class="step-3">
    <v-layout justify-center>
      <v-flex shrink>
        <v-img
          src="../../assets/images/step3-gender.png"
          height="120"
          contain
        ></v-img>
      </v-flex>
    </v-layout>

    <div class="step-title text-center my-6">เพศ</div>

    <v-row justify="center" align="center" class="mt-16">
      <v-col cols="auto">
        <box-card
          width="140"
          height="140"
          :backgroundColor="form.gender === 'M' ? '#D4EDEE' : '#ffffff'"
        >
          <v-layout
            wrap
            style="height: 137px; cursor: pointer"
            justify-center
            align-center
            @click="setGender('M')"
          >
            <v-flex xs12 class="d-flex justify-center">
              <Icon style="font-size: 5rem" icon="ph:gender-male-bold" />
            </v-flex>
            <v-flex xs12 class="d-flex justify-center">
              <span class="font-24 font-w-500">ชาย</span>
            </v-flex>
          </v-layout>
        </box-card>
      </v-col>
      <v-col cols="auto">
        <box-card
          width="140"
          height="140"
          :backgroundColor="form.gender === 'F' ? '#D4EDEE' : '#ffffff'"
        >
          <v-layout
            wrap
            style="height: 137px; cursor: pointer"
            justify-center
            align-center
            @click="setGender('F')"
          >
            <v-flex xs12 class="d-flex justify-center">
              <Icon style="font-size: 5rem" icon="ph:gender-female-bold" />
            </v-flex>
            <v-flex xs12 class="d-flex justify-center">
              <span class="font-24 font-w-500">หญิง</span>
            </v-flex>
          </v-layout>
        </box-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import { mapState } from "vuex";
import "vue-scroll-picker/dist/style.css";
import BoxCard from "../BoxCard.vue";

export default {
  components: { BoxCard, Icon },
  name: "QuestionnaireForm",

  computed: {
    ...mapState({
      step: (state) => state.questionnaire.step,
      form: (state) => state.questionnaire.form,
    }),
  },

  methods: {
    setGender(gender) {
      // call mutation
      this.$store.commit("questionnaire/setGender", gender);
    },
  },
};
</script>

<style lang="scss"></style>
