var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-12"},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-img',{attrs:{"src":require("../../assets/images/step-13.png"),"height":"120","contain":""}})],1)],1),_vm._m(0),_c('div',{staticStyle:{"padding-bottom":"8rem"}},[_c('box-card',{staticClass:"py-3 custom_checkbox_radio"},[_c('v-radio-group',{attrs:{"rules":[(v) => !!v || 'กรุณาตอบคำถาม']},on:{"change":_vm.onChangeRadio},model:{value:(_vm.form.question_13_smoke),callback:function ($$v) {_vm.$set(_vm.form, "question_13_smoke", $$v)},expression:"form.question_13_smoke"}},[_c('v-radio',{staticClass:"mb-6 px-3",attrs:{"color":"#C71010","value":"ไม่สูบ"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-24",staticStyle:{"color":"#000000"}},[_vm._v(" ไม่สูบ ")])]},proxy:true}])}),_c('v-divider'),_c('v-radio',{staticClass:"mt-6 mb-3 px-3",attrs:{"color":"#C71010","value":"สูบ ประมาณ"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-24",staticStyle:{"color":"#000000"}},[_vm._v(" สูบ ประมาณ ")])]},proxy:true}])}),_c('v-layout',{attrs:{"align-center":"","px-5":"","pb-6":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-autocomplete',{attrs:{"items":_vm.pieces,"disabled":_vm.form.question_13_smoke != 'สูบ ประมาณ',"hide-details":"","menu-props":"offsetY","color":"#000","outlined":"","rules":_vm.form.question_13_smoke == 'สูบ ประมาณ'
                  ? [(v) => !!v || 'กรุณาเลือกจำนวน']
                  : [],"required":""},model:{value:(_vm.form.question_13_smoke_day),callback:function ($$v) {_vm.$set(_vm.form, "question_13_smoke_day", $$v)},expression:"form.question_13_smoke_day"}})],1),_c('v-flex',{staticClass:"pl-2 font-24",style:(_vm.form.question_13_smoke != 'สูบ ประมาณ'
                ? { color: '#A3A3A3' }
                : { color: '#000' }),attrs:{"xs6":""}},[_vm._v(" มวน/วัน ")])],1),_c('v-divider'),_c('v-radio',{staticClass:"mt-6 mb-3 px-3",attrs:{"color":"#C71010","value":"เคยสูบ แต่ตอนนี้เลิกแล้ว เมื่อ"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-24",staticStyle:{"color":"#000000"}},[_vm._v(" เคยสูบ แต่ตอนนี้เลิกแล้ว เมื่อ ")])]},proxy:true}])}),_c('v-layout',{attrs:{"align-center":"","px-5":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-autocomplete',{attrs:{"items":_vm.years,"disabled":_vm.form.question_13_smoke != 'เคยสูบ แต่ตอนนี้เลิกแล้ว เมื่อ',"hide-details":"","menu-props":"offsetY","color":"#000","outlined":"","rules":_vm.form.question_13_smoke == 'เคยสูบ แต่ตอนนี้เลิกแล้ว เมื่อ'
                  ? [(v) => !!v || 'กรุณาเลือกจำนวน']
                  : [],"required":""},model:{value:(_vm.form.question_13_smoke_year),callback:function ($$v) {_vm.$set(_vm.form, "question_13_smoke_year", $$v)},expression:"form.question_13_smoke_year"}})],1),_c('v-flex',{staticClass:"pl-2 font-24",style:(_vm.form.question_13_smoke != 'เคยสูบ แต่ตอนนี้เลิกแล้ว เมื่อ'
                ? { color: '#A3A3A3' }
                : { color: '#000' }),attrs:{"xs6":""}},[_vm._v(" ปีที่แล้ว ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-title text-center"},[_vm._v(" คุณสูบบุหรี่ "),_c('br'),_vm._v(" หรือไม่? ")])
}]

export { render, staticRenderFns }