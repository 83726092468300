<template>
  <div class="step-10">
    <div class="step-title text-center mt-6">
      อาการ/ปัญหา <br />
      เกิดขึ้นในเวลาใด?
    </div>

    <div style="color: #a3a3a3" class="font-20 text-center mb-4">
      (*เลือกตอบได้หลายข้อ)
    </div>

    <div style="padding-bottom: 8rem">
      <box-card class="custom_checkbox_radio">
        <!-- ตอนที่ตื่นนอน -->
        <div class="px-4 pt-4">
          <div class="font-24 font-w-600">ตอนที่ตื่นนอน</div>
          <v-layout align-end justify-space-between>
            <v-flex shrink>
              <v-radio-group
                class="mt-0"
                v-model="
                  form.body_problem_concern_questionnaire[indexAt]
                    .question_10_wakeup
                "
                :rules="[(v) => !!v || 'กรุณาเลือกตอนที่ตื่นนอน']"
              >
                <v-radio
                  v-for="(item, i) in radioList"
                  :key="i"
                  color="#C71010"
                  :value="item"
                  class="pb-4"
                >
                  <template v-slot:label>
                    <span class="font-20" style="color: #000000">
                      {{ item }}
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex shrink pb-2>
              <v-img
                width="80"
                aspect-ratio="1"
                src="../../assets/images/step10-1.png"
              ></v-img>
            </v-flex>
          </v-layout>
        </div>

        <v-divider></v-divider>

        <!-- ตอนที่ยืนอยู่ -->
        <div class="px-4 pt-4">
          <div class="font-24 font-w-600">ตอนที่ยืนอยู่</div>
          <v-layout align-end justify-space-between>
            <v-flex shrink>
              <v-radio-group
                class="mt-0"
                v-model="
                  form.body_problem_concern_questionnaire[indexAt]
                    .question_10_standing
                "
                :rules="[(v) => !!v || 'กรุณาเลือกตอนที่ยืนอยู่']"
              >
                <v-radio
                  v-for="(item, i) in radioList"
                  :key="i"
                  color="#C71010"
                  :value="item"
                  class="pb-4"
                >
                  <template v-slot:label>
                    <span class="font-20" style="color: #000000">
                      {{ item }}
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex shrink pb-2>
              <v-img
                width="80"
                aspect-ratio="1"
                src="../../assets/images/step10-2.png"
              ></v-img>
            </v-flex>
          </v-layout>
        </div>

        <v-divider></v-divider>

        <!-- ตอนที่เดินอยู่ -->
        <div class="px-4 pt-4">
          <div class="font-24 font-w-600">ตอนที่เดินอยู่</div>
          <v-layout align-end justify-space-between>
            <v-flex shrink>
              <v-radio-group
                class="mt-0"
                v-model="
                  form.body_problem_concern_questionnaire[indexAt]
                    .question_10_walking
                "
                :rules="[(v) => !!v || 'กรุณาเลือกตอนที่เดินอยู่']"
                required
              >
                <v-radio
                  v-for="(item, i) in radioList"
                  :key="i"
                  color="#C71010"
                  :value="item"
                  class="pb-4"
                >
                  <template v-slot:label>
                    <span class="font-20" style="color: #000000">
                      {{ item }}
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex shrink pb-2>
              <v-img
                width="80"
                aspect-ratio="1"
                src="../../assets/images/step10-3.png"
              ></v-img>
            </v-flex>
          </v-layout>
        </div>

        <v-divider></v-divider>

        <!-- ตอนที่นั่งอยู่ -->
        <div class="px-4 pt-4">
          <div class="font-24 font-w-600">ตอนที่นั่งอยู่</div>
          <v-layout align-end justify-space-between>
            <v-flex shrink>
              <v-radio-group
                class="mt-0"
                v-model="
                  form.body_problem_concern_questionnaire[indexAt]
                    .question_10_sitting
                "
                :rules="[(v) => !!v || 'กรุณาเลือกตอนที่นั่งอยู่']"
              >
                <v-radio
                  v-for="(item, i) in radioList"
                  :key="i"
                  color="#C71010"
                  :value="item"
                  class="pb-4"
                >
                  <template v-slot:label>
                    <span class="font-20" style="color: #000000">
                      {{ item }}
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex shrink pb-2>
              <v-img
                width="80"
                aspect-ratio="1"
                src="../../assets/images/step10-4.png"
              ></v-img>
            </v-flex>
          </v-layout>
        </div>

        <v-divider></v-divider>

        <!-- ตอนที่เคลื่อนไหวบางอิริยาบท -->
        <div class="px-4 pt-4">
          <div class="font-24 font-w-600">ตอนที่เคลื่อนไหวบางอิริยาบท</div>
          <v-layout align-end justify-space-between>
            <v-flex shrink>
              <v-radio-group
                class="mt-0"
                v-model="
                  form.body_problem_concern_questionnaire[indexAt]
                    .question_10_moving
                "
                :rules="[(v) => !!v || 'กรุณาเลือกตอนที่เคลื่อนไหวบางอิริยาบท']"
              >
                <v-radio
                  v-for="(item, i) in radioList"
                  :key="i"
                  color="#C71010"
                  :value="item"
                  class="pb-4"
                >
                  <template v-slot:label>
                    <span class="font-20" style="color: #000000">
                      {{ item }}
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex shrink pb-2>
              <v-img
                width="80"
                aspect-ratio="1"
                src="../../assets/images/step10-5.png"
              ></v-img>
            </v-flex>
          </v-layout>
        </div>

        <v-divider></v-divider>

        <!-- ตอนที่นอนอยู่ -->
        <div class="px-4 pt-4">
          <div class="font-24 font-w-600">ตอนที่นอนอยู่</div>
          <v-layout align-end justify-space-between>
            <v-flex shrink>
              <v-radio-group
                class="mt-0"
                v-model="
                  form.body_problem_concern_questionnaire[indexAt]
                    .question_10_sleeping
                "
                :rules="[(v) => !!v || 'กรุณาเลือกตอนที่นอนอยู่']"
              >
                <v-radio
                  v-for="(item, i) in radioList"
                  :key="i"
                  color="#C71010"
                  :value="item"
                  class="pb-4"
                >
                  <template v-slot:label>
                    <span class="font-20" style="color: #000000">
                      {{ item }}
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex shrink pb-2>
              <v-img
                width="80"
                aspect-ratio="1"
                src="../../assets/images/step10-6.png"
              ></v-img>
            </v-flex>
          </v-layout>
        </div>
      </box-card>
    </div>
  </div>
</template>

<script>
// import { Icon } from "@iconify/vue2";
import { mapState } from "vuex";
import "vue-scroll-picker/dist/style.css";
import BoxCard from "../BoxCard.vue";

export default {
  components: { BoxCard },
  // components: { Icon },
  name: "QuestionnaireForm",

  props: {
    indexAt: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      weeks: [1, 2, 3, 4],

      radioList: ["เป็นประจำ", "บ่อยครั้ง", "บางครั้ง", "ไม่เคย"],
    };
  },

  computed: {
    ...mapState({
      step: (state) => state.questionnaire.step,
      form: (state) => state.questionnaire.form,
    }),
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {},
};
</script>

<style lang="scss">
.step-10 {
  .v-select.v-text-field input {
    // font-size: 24px;
  }
}
</style>
