<template>
  <div class="step-1">
    <div class="step-title">
      แบบสอบถาม <br />
      ตรวจอายุร่างกาย
    </div>

    <div class="font-24 font-w-600">
      {{ formatTel(form.tel) }}
    </div>

    <v-layout justify-center>
      <v-flex shrink>
        <v-img src="../../assets/images/step1-info.png" width="120"></v-img>
      </v-flex>
    </v-layout>

    <div>
      <label class="font-24 font-w-600">ชื่อจริง</label>
      <text-field
        :outlined="true"
        :required="true"
        :model="form.first_name"
        :rules="[(v) => !!v || 'กรุณากรอกชื่อจริง']"
        @update:data="form.first_name = $event"
      ></text-field>
    </div>

    <div>
      <label class="font-24 font-w-600">นามสกุล</label>
      <text-field
        :outlined="true"
        :required="true"
        :model="form.last_name"
        :rules="[(v) => !!v || 'กรุณากรอกนามสกุล']"
        @update:data="form.last_name = $event"
      ></text-field>
    </div>

    <div>
      <label class="font-24 font-w-600">ชื่อเล่น</label>
      <text-field
        :outlined="true"
        :required="true"
        :model="form.nickname"
        :rules="[(v) => !!v || 'กรุณากรอกชื่อเล่น']"
        @update:data="form.nickname = $event"
      ></text-field>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TextField from "../TextField.vue";
export default {
  components: { TextField },
  name: "QuestionnaireForm",

  computed: {
    ...mapState({
      step: (state) => state.questionnaire.step,
      form: (state) => state.questionnaire.form,
    }),
  },

  methods: {
    formatTel(tel) {
      return tel.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    },
  },
};
</script>

<style></style>
