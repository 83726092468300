var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-5"},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-img',{attrs:{"src":require("../../assets/images/step6-problem.png"),"height":"120","contain":""}})],1)],1),_vm._m(0),_vm._m(1),_c('div',{staticStyle:{"padding-bottom":"8rem"}},[_c('BoxCard',{staticClass:"custom_checkbox_radio"},[_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value1 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"","color":"#C71010","label":"ความเจ็บปวด","value":"ความเจ็บปวด","disabled":_vm.checkboxForm.value1 !== 'ความเจ็บปวด' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('ความเจ็บปวด', $event)}},model:{value:(_vm.checkboxForm.value1),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value1", $$v)},expression:"checkboxForm.value1"}})],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value2 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"ตัวเย็นขี้หนาว","value":"ตัวเย็นขี้หนาว","disabled":_vm.checkboxForm.value2 !== 'ตัวเย็นขี้หนาว' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('ตัวเย็นขี้หนาว', $event)}},model:{value:(_vm.checkboxForm.value2),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value2", $$v)},expression:"checkboxForm.value2"}})],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value3 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"ร้อนวูบวาบ","value":"ร้อนวูบวาบ","disabled":_vm.checkboxForm.value3 !== 'ร้อนวูบวาบ' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('ร้อนวูบวาบ', $event)}},model:{value:(_vm.checkboxForm.value3),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value3", $$v)},expression:"checkboxForm.value3"}})],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value4 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"บวม","value":"บวม","disabled":_vm.checkboxForm.value4 !== 'บวม' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('บวม', $event)}},model:{value:(_vm.checkboxForm.value4),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value4", $$v)},expression:"checkboxForm.value4"}})],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value5 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"วิงเวียนศีรษะ","value":"วิงเวียนศีรษะ","disabled":_vm.checkboxForm.value5 !== 'วิงเวียนศีรษะ' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('วิงเวียนศีรษะ', $event)}},model:{value:(_vm.checkboxForm.value5),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value5", $$v)},expression:"checkboxForm.value5"}})],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value6 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"ใจสั่น","value":"ใจสั่น","disabled":_vm.checkboxForm.value6 !== 'ใจสั่น' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('ใจสั่น', $event)}},model:{value:(_vm.checkboxForm.value6),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value6", $$v)},expression:"checkboxForm.value6"}})],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value7 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"หายใจถี่","value":"หายใจถี่","disabled":_vm.checkboxForm.value7 !== 'หายใจถี่' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('หายใจถี่', $event)}},model:{value:(_vm.checkboxForm.value7),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value7", $$v)},expression:"checkboxForm.value7"}})],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value8 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"อ่อนเพลีย","value":"อ่อนเพลีย","disabled":_vm.checkboxForm.value8 !== 'อ่อนเพลีย' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('อ่อนเพลีย', $event)}},model:{value:(_vm.checkboxForm.value8),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value8", $$v)},expression:"checkboxForm.value8"}})],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value9 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"หูอื้อหูมีเสียง","value":"หูอื้อหูมีเสียง","disabled":_vm.checkboxForm.value9 !== 'หูอื้อหูมีเสียง' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('หูอื้อหูมีเสียง', $event)}},model:{value:(_vm.checkboxForm.value9),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value9", $$v)},expression:"checkboxForm.value9"}})],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value10 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"น้ำหนักเพิ่มขึ้น","value":"น้ำหนักเพิ่มขึ้น","disabled":_vm.checkboxForm.value10 !== 'น้ำหนักเพิ่มขึ้น' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('น้ำหนักเพิ่มขึ้น', $event)}},model:{value:(_vm.checkboxForm.value10),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value10", $$v)},expression:"checkboxForm.value10"}})],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value11 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"ความดันโลหิตสูง","value":"ความดันโลหิตสูง","disabled":_vm.checkboxForm.value11 !== 'ความดันโลหิตสูง' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('ความดันโลหิตสูง', $event)}},model:{value:(_vm.checkboxForm.value11),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value11", $$v)},expression:"checkboxForm.value11"}})],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value12 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"ความดันโลหิตต่ำ","value":"ความดันโลหิตต่ำ","disabled":_vm.checkboxForm.value12 !== 'ความดันโลหิตต่ำ' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('ความดันโลหิตต่ำ', $event)}},model:{value:(_vm.checkboxForm.value12),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value12", $$v)},expression:"checkboxForm.value12"}})],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value13 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"ความผิดปกติของการนอน","value":"ความผิดปกติของการนอน","disabled":_vm.checkboxForm.value13 !== 'ความผิดปกติของการนอน' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('ความผิดปกติของการนอน', $event)}},model:{value:(_vm.checkboxForm.value13),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value13", $$v)},expression:"checkboxForm.value13"}})],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value14 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"ท้องผูก","value":"ท้องผูก","disabled":_vm.checkboxForm.value14 !== 'ท้องผูก' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('ท้องผูก', $event)}},model:{value:(_vm.checkboxForm.value14),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value14", $$v)},expression:"checkboxForm.value14"}})],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value15 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"ปัสสาวะบ่อย","value":"ปัสสาวะบ่อย","disabled":_vm.checkboxForm.value15 !== 'ปัสสาวะบ่อย' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('ปัสสาวะบ่อย', $event)}},model:{value:(_vm.checkboxForm.value15),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value15", $$v)},expression:"checkboxForm.value15"}})],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value16 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"อื่นๆ","value":"อื่นๆ","disabled":_vm.checkboxForm.value16 !== 'อื่นๆ' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('อื่นๆ', $event)}},model:{value:(_vm.checkboxForm.value16),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value16", $$v)},expression:"checkboxForm.value16"}}),(_vm.checkboxForm.value16)?_c('v-textarea',{staticClass:"mt-2",attrs:{"rules":[
            (v) => !!v || 'กรุณากรอกข้อมูล',
            (v) =>
              (v && v.length <= 150) || 'กรุณากรอกข้อมูลไม่เกิน 150 ตัวอักษร',
          ],"rows":"3","color":"#000","background-color":"#fff","placeholder":"โปรดระบุ","outlined":""},on:{"input":function($event){return _vm.onInput('อื่นๆ', $event)}},model:{value:(_vm.checkboxForm.value16_other),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value16_other", $$v)},expression:"checkboxForm.value16_other"}}):_vm._e()],1),_c('v-divider'),_c('div',{staticClass:"pa-3",class:_vm.checkboxForm.value17 ? 'active-checked' : ''},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"#C71010","hide-details":"","label":"โรคเรื้อรัง","value":"โรคเรื้อรัง","disabled":_vm.checkboxForm.value17 !== 'โรคเรื้อรัง' &&
            _vm.form.body_problem_concern.length >= 3},on:{"change":function($event){return _vm.onSelect('โรคเรื้อรัง', $event)}},model:{value:(_vm.checkboxForm.value17),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value17", $$v)},expression:"checkboxForm.value17"}}),(_vm.checkboxForm.value17)?_c('v-textarea',{staticClass:"mt-2",attrs:{"rules":[
            (v) => !!v || 'กรุณากรอกข้อมูล',
            (v) =>
              (v && v.length <= 150) || 'กรุณากรอกข้อมูลไม่เกิน 150 ตัวอักษร',
          ],"rows":"3","color":"#000","background-color":"#fff","placeholder":"โปรดระบุ","outlined":""},on:{"input":function($event){return _vm.onInput('โรคเรื้อรัง', $event)}},model:{value:(_vm.checkboxForm.value17_other),callback:function ($$v) {_vm.$set(_vm.checkboxForm, "value17_other", $$v)},expression:"checkboxForm.value17_other"}}):_vm._e()],1),(!_vm.isValid)?_c('div',{staticClass:"px-3 pb-3"},[_c('span',{staticClass:"font-12",staticStyle:{"color":"red"}},[_vm._v(" กรุณาเลือกอย่างน้อย 1 ตัวเลือก ")])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-title text-center my-4"},[_vm._v(" \"ปัญหาร่างกาย/ "),_c('br'),_vm._v(" ความกังวล\"ที่ต้องการ "),_c('br'),_vm._v(" แก้ไขคืออะไรบ้าง? ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-20 mb-6 text-center",staticStyle:{"color":"#a3a3a3"}},[_vm._v(" (*แม้จะเป็นโรคไม่ติดต่อเรื้อรัง) "),_c('br'),_vm._v(" (*เลือกตอบได้หลายข้อสูงสุด 3 ข้อ) ")])
}]

export { render, staticRenderFns }