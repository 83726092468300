import Service from "../service";

const questionnaire = {
  saveQuestionnaire(token, payload) {
    return Service.post(`/api/questionnaire/save?token=${token}`, payload)
      .then((res) => res)
      .catch((err) => err);
  },
  checkLink({ token, password }) {
    return Service.get(
      `/api/questionnaire/check-link?token=${token}&password=${password}`
    ).then((res) => res);
  },
  checkToken(token) {
    return Service.get(`/api/questionnaire/check-token?token=${token}`).then(
      (res) => res
    );
  },
  getMasterData() {
    return Service.get(`/api/questionnaire/master-data`).then((res) => res);
  },
  getAmphureWithProvinceByZipcode(zipcode) {
    return Service.get(
      `/api/questionnaire/get-amphure-with-province-by-zipcode?zipcode=${zipcode}`
    ).then((res) => res);
  },
};

export default questionnaire;
