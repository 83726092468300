var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-10"},[_vm._m(0),_c('div',{staticClass:"font-20 text-center mb-4",staticStyle:{"color":"#a3a3a3"}},[_vm._v(" (*เลือกตอบได้หลายข้อ) ")]),_c('div',{staticStyle:{"padding-bottom":"8rem"}},[_c('box-card',{staticClass:"custom_checkbox_radio"},[_c('div',{staticClass:"px-4 pt-4"},[_c('div',{staticClass:"font-24 font-w-600"},[_vm._v("ตอนที่ตื่นนอน")]),_c('v-layout',{attrs:{"align-end":"","justify-space-between":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"rules":[(v) => !!v || 'กรุณาเลือกตอนที่ตื่นนอน']},model:{value:(
                _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  .question_10_wakeup
              ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  , "question_10_wakeup", $$v)},expression:"\n                form.body_problem_concern_questionnaire[indexAt]\n                  .question_10_wakeup\n              "}},_vm._l((_vm.radioList),function(item,i){return _c('v-radio',{key:i,staticClass:"pb-4",attrs:{"color":"#C71010","value":item},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-20",staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(item)+" ")])]},proxy:true}],null,true)})}),1)],1),_c('v-flex',{attrs:{"shrink":"","pb-2":""}},[_c('v-img',{attrs:{"width":"80","aspect-ratio":"1","src":require("../../assets/images/step10-1.png")}})],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"px-4 pt-4"},[_c('div',{staticClass:"font-24 font-w-600"},[_vm._v("ตอนที่ยืนอยู่")]),_c('v-layout',{attrs:{"align-end":"","justify-space-between":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"rules":[(v) => !!v || 'กรุณาเลือกตอนที่ยืนอยู่']},model:{value:(
                _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  .question_10_standing
              ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  , "question_10_standing", $$v)},expression:"\n                form.body_problem_concern_questionnaire[indexAt]\n                  .question_10_standing\n              "}},_vm._l((_vm.radioList),function(item,i){return _c('v-radio',{key:i,staticClass:"pb-4",attrs:{"color":"#C71010","value":item},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-20",staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(item)+" ")])]},proxy:true}],null,true)})}),1)],1),_c('v-flex',{attrs:{"shrink":"","pb-2":""}},[_c('v-img',{attrs:{"width":"80","aspect-ratio":"1","src":require("../../assets/images/step10-2.png")}})],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"px-4 pt-4"},[_c('div',{staticClass:"font-24 font-w-600"},[_vm._v("ตอนที่เดินอยู่")]),_c('v-layout',{attrs:{"align-end":"","justify-space-between":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"rules":[(v) => !!v || 'กรุณาเลือกตอนที่เดินอยู่'],"required":""},model:{value:(
                _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  .question_10_walking
              ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  , "question_10_walking", $$v)},expression:"\n                form.body_problem_concern_questionnaire[indexAt]\n                  .question_10_walking\n              "}},_vm._l((_vm.radioList),function(item,i){return _c('v-radio',{key:i,staticClass:"pb-4",attrs:{"color":"#C71010","value":item},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-20",staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(item)+" ")])]},proxy:true}],null,true)})}),1)],1),_c('v-flex',{attrs:{"shrink":"","pb-2":""}},[_c('v-img',{attrs:{"width":"80","aspect-ratio":"1","src":require("../../assets/images/step10-3.png")}})],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"px-4 pt-4"},[_c('div',{staticClass:"font-24 font-w-600"},[_vm._v("ตอนที่นั่งอยู่")]),_c('v-layout',{attrs:{"align-end":"","justify-space-between":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"rules":[(v) => !!v || 'กรุณาเลือกตอนที่นั่งอยู่']},model:{value:(
                _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  .question_10_sitting
              ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  , "question_10_sitting", $$v)},expression:"\n                form.body_problem_concern_questionnaire[indexAt]\n                  .question_10_sitting\n              "}},_vm._l((_vm.radioList),function(item,i){return _c('v-radio',{key:i,staticClass:"pb-4",attrs:{"color":"#C71010","value":item},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-20",staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(item)+" ")])]},proxy:true}],null,true)})}),1)],1),_c('v-flex',{attrs:{"shrink":"","pb-2":""}},[_c('v-img',{attrs:{"width":"80","aspect-ratio":"1","src":require("../../assets/images/step10-4.png")}})],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"px-4 pt-4"},[_c('div',{staticClass:"font-24 font-w-600"},[_vm._v("ตอนที่เคลื่อนไหวบางอิริยาบท")]),_c('v-layout',{attrs:{"align-end":"","justify-space-between":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"rules":[(v) => !!v || 'กรุณาเลือกตอนที่เคลื่อนไหวบางอิริยาบท']},model:{value:(
                _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  .question_10_moving
              ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  , "question_10_moving", $$v)},expression:"\n                form.body_problem_concern_questionnaire[indexAt]\n                  .question_10_moving\n              "}},_vm._l((_vm.radioList),function(item,i){return _c('v-radio',{key:i,staticClass:"pb-4",attrs:{"color":"#C71010","value":item},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-20",staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(item)+" ")])]},proxy:true}],null,true)})}),1)],1),_c('v-flex',{attrs:{"shrink":"","pb-2":""}},[_c('v-img',{attrs:{"width":"80","aspect-ratio":"1","src":require("../../assets/images/step10-5.png")}})],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"px-4 pt-4"},[_c('div',{staticClass:"font-24 font-w-600"},[_vm._v("ตอนที่นอนอยู่")]),_c('v-layout',{attrs:{"align-end":"","justify-space-between":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"rules":[(v) => !!v || 'กรุณาเลือกตอนที่นอนอยู่']},model:{value:(
                _vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  .question_10_sleeping
              ),callback:function ($$v) {_vm.$set(_vm.form.body_problem_concern_questionnaire[_vm.indexAt]
                  , "question_10_sleeping", $$v)},expression:"\n                form.body_problem_concern_questionnaire[indexAt]\n                  .question_10_sleeping\n              "}},_vm._l((_vm.radioList),function(item,i){return _c('v-radio',{key:i,staticClass:"pb-4",attrs:{"color":"#C71010","value":item},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-20",staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(item)+" ")])]},proxy:true}],null,true)})}),1)],1),_c('v-flex',{attrs:{"shrink":"","pb-2":""}},[_c('v-img',{attrs:{"width":"80","aspect-ratio":"1","src":require("../../assets/images/step10-6.png")}})],1)],1)],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-title text-center mt-6"},[_vm._v(" อาการ/ปัญหา "),_c('br'),_vm._v(" เกิดขึ้นในเวลาใด? ")])
}]

export { render, staticRenderFns }